import { Badge } from "@chakra-ui/react";

const colorScheme = {
  created: "yellow",
  completed: "green",
  archived: null,
};

export default function EventStatusBadge({ state }) {
  return <Badge colorScheme={colorScheme[state]}>{state}</Badge>;
}
