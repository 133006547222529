import { Flex } from "@chakra-ui/react";

export default function Footer() {
  return (
    <Flex justifyContent="flex-end" bg="gray.700">
      <MotorsportRegAttribution />
    </Flex>
  );
}

// MSR attribution required per https://api.motorsportreg.com
const MotorsportRegAttribution = () => (
  <a
    href="https://motorsportreg.com"
    style={{ display: "inline-block", padding: "15px" }}
    target="_blank"
  >
    <img
      src="https://msr-hotlink.s3.amazonaws.com/powered-by/powered-by-msr-outline@2x.png"
      alt="Online registration and event management service for motorsport events powered by MotorsportReg.com"
      title="Online registration and event management service for motorsport events powered by MotorsportReg.com"
      style={{ width: "165px", height: "29px" }}
    />
  </a>
);
