import { get } from "lodash";
import {
  Button,
  Text,
  FormLabel,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Progress,
  Heading,
  Table,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";
// import { useIndexFactorGroups} from "../../queries";

export default function Review({
  event,
  selectedSeries,
  close,
  importEvent,
  error,
}) {
  const handleNext = importEvent;

  console.log("selectedSeries", selectedSeries);

  let errorMsg;
  if (error && error.response) {
    errorMsg =
      get(error, "response.data.errors", []).join(" ") || "unknown error";
  }

  // const {
  //   isLoading: isPaxConfigsLoading,
  //   isError: isPaxConfigsError,
  //   data: paxConfigData,
  // } = useIndexFactorGroups();

  // const {
  //   isLoading: isMsrGroupsLoading,
  //   isError: isMsrGroupsError,
  //   data: groups,
  // } = useMsrGroups(
  //   event.id
  //   // selectedMsrSegments.map((s) => s.id)
  // );

  // const isLoading = isPaxConfigsLoading; // || isMsrGroupsLoading;
  // const isError = isPaxConfigsError; // || isMsrGroupsError;

  return (
    <ModalContent>
      <ModalHeader>Import MSR event</ModalHeader>

      <ModalBody>
        <FormLabel>Review your selections</FormLabel>
        <Table variant="simple">
          <Tbody>
            <Tr>
              <Td>Event</Td>
              <Td>
                <Text>{event.name}</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>Start Date</Td>
              <Td>
                <Text>{event.start_date}</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>Series</Td>
              <Td>
                <Text>{event.event_series_name}</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>PAX Configuration</Td>
              <Td>{event.index_factor_group_name}</Td>
            </Tr>
          </Tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button onClick={close} ml={3}>
          Back
        </Button>
        <Button colorScheme="blue" onClick={handleNext} ml={3}>
          Import
        </Button>
      </ModalFooter>
      {errorMsg && (
        <ModalBody>
          <Heading size="sm" pb={4} color="red.500">
            Unable to import MSR event.
            <br />
            <br />
            {errorMsg}
          </Heading>
        </ModalBody>
      )}
    </ModalContent>
  );
}
