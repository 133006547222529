import _ from "lodash";
import { createRef, useRef, useState, useEffect, useCallback } from "react";
import EntryInfo from "./EntryInfo";
import TimingCell from "./TimingCell";
import { nextSelectedRun, scrollToCell } from "../../cell-router";
import { useWindowEvent } from "../../hooks";
import EntryForm from "../event-detail/EntryForm";
import SwapEntry from "./SwapEntry";
import { runsSig } from "./logger/utils";

function TimingTable({
  timingEnabled,
  debugEnabled,
  event,
  eventId,
  entries,
  runs,
  queuedRunLogs,
  selectedRunGroup,
  updateCacheForEntry,
  updateEntryRun,
}) {
  console.log("rendering TimingTable");
  const [selectedRun, setSelectedRun] = useState(() =>
    nextSelectedRun(runs, entries, [])
  );
  const [isEditEntryModalOpen, setIsEditEntryModalOpen] = useState(false);
  const [isSwapEntryModalOpen, setIsSwapEntryModalOpen] = useState(false);
  const [editingEntry, setEditingEntry] = useState({});

  const createCellRefs = () => {
    let refs = {};
    entries.forEach((entry) => {
      refs[entry.entry_id] = [];
      entry.runs.forEach((run, idx) => {
        refs[entry.entry_id][idx] = createRef();
      });
    });
    return refs;
  };

  useEffect(() => {
    if (!timingEnabled) {
      setSelectedRun([]);
    }
  }, [timingEnabled]);

  const advanceToNextSelectedRun = useCallback(() => {
    const nextRun = nextSelectedRun(runs, entries, selectedRun);

    if (!nextRun || nextRun.length > 1) {
      const el = cellRefs.current[selectedRun[0]]
        ? cellRefs.current[selectedRun[0]][selectedRun[1]].current
        : null;
      if (el) {
        el.blur();
        setSelectedRun([]);
        // return;
      }

      const el2 = cellRefs.current[nextRun[0]]
        ? cellRefs.current[nextRun[0]][nextRun[1]].current
        : null;
      if (el2) {
        el2.focus();
        scrollToCell(el2);
      }
    }
  }, [selectedRun]);

  const cellRefs = useRef(createCellRefs());

  useEffect(() => {
    let refs = {};
    entries.forEach((entry) => {
      refs[entry.entry_id] = [];
      entry.runs.forEach((run, idx) => {
        if (!refs[entry.entry_id][idx]) {
          refs[entry.entry_id][idx] = createRef();
        }
      });
    });
    cellRefs.current = refs;
  }, [entries]);

  useEffect(() => {
    const selected = nextSelectedRun(runs, entries, []);

    if (
      selected &&
      cellRefs.current[selected[0]] &&
      cellRefs.current[selected[0]][selected[1]].current
    ) {
      const inputEl = cellRefs.current[selected[0]][selected[1]].current;
      setSelectedRun(selected);
      inputEl.focus();
      scrollToCell(inputEl);
    }
  }, [selectedRunGroup]);

  const handleEntryEdit = (entry) => {
    setEditingEntry(entry);
    setIsEditEntryModalOpen(true);
  };

  const handleEntryEditClose = (ev) => {
    setIsEditEntryModalOpen(false);
    setEditingEntry({});
  };

  const handleEntrySwap = (entry) => {
    setEditingEntry(entry);
    setIsSwapEntryModalOpen(true);
  };

  const handleEntrySwapClose = (ev) => {
    setIsSwapEntryModalOpen(false);
    setEditingEntry({});
  };

  return (
    <div style={styles.container}>
      <table style={styles.table} id="timing-table">
        <thead>
          <tr>
            <th style={styles.entriesCell}>ENTRIES</th>
            {runs.map((run) => (
              <th style={styles.runCell} key={`run-${run}`}>
                R{run}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {entries.map((entry, entryIdx) => (
            <tr key={`entry-${entry.entry_id}`}>
              <th style={styles.entryCell}>
                <EntryInfo
                  timingEnabled={timingEnabled}
                  event={event}
                  entry={entry}
                  isSelectedEntry={selectedRun[0] === entry.entry_id}
                  onEdit={handleEntryEdit}
                  onSwap={handleEntrySwap}
                />
              </th>
              {runs.map((run, runIdx) => {
                const entryRun = entry.runs[runIdx];

                return (
                  <th
                    style={
                      entry.runs[runIdx] ? styles.timingCell : styles.blankCell
                    }
                    key={`timing-cell-${entry.entry_id}-${runIdx}}`}
                  >
                    <TimingCell
                      queueIdx={queuedRunLogs.indexOf(
                        queuedRunLogs.find((l) => l.run_id === entryRun.id)
                      )}
                      runsSig={runsSig(entry)}
                      timingEnabled={timingEnabled}
                      debugEnabled={debugEnabled}
                      event={event}
                      eventId={eventId}
                      runs={runs}
                      entry={entry}
                      entryIdx={entryIdx}
                      runIdx={runIdx}
                      isSelectedRun={
                        selectedRun[0] === entry.entry_id &&
                        selectedRun[1] === runIdx
                      }
                      tabIndex={entryIdx + 1 + entries.length * runIdx}
                      setSelectedRun={setSelectedRun}
                      advanceToNextSelectedRun={advanceToNextSelectedRun}
                      cellRef={
                        cellRefs.current[entry.entry_id]
                          ? cellRefs.current[entry.entry_id][runIdx]
                          : null
                      }
                      isBestRun={bestRunIdx(entry.runs) === runIdx}
                      updateEntryRun={updateEntryRun}
                      updateCacheForEntry={updateCacheForEntry}
                    />
                  </th>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <EntryForm
        eventId={eventId}
        entry={editingEntry}
        entries={entries}
        isOpen={isEditEntryModalOpen}
        onClose={handleEntryEditClose}
        onMutate={updateCacheForEntry}
      />
      <SwapEntry
        entry={editingEntry}
        entries={entries}
        isOpen={isSwapEntryModalOpen}
        onClose={handleEntrySwapClose}
        onMutate={updateCacheForEntry}
      />
    </div>
  );
}

function bestRunIdx(runs) {
  const sortedRuns = _.sortBy(runs, (r) => {
    const value =
      r.scratchTime <= 0 || r.dnf || r.scratchTime === null
        ? 999999999
        : r.scratchTime + (r.penaltyCount || 0) * 2;
    return value;
  });
  return runs.indexOf(sortedRuns[0]);
}

const styles = {
  container: {
    maxHeight: "100vh",
    // paddingTop: "50px",
    overflow: "scroll",
    width: "calc(100% - 400px)",
    position: "relative",
  },
  table: {
    tableLayout: "fixed",
    borderCollapse: "collapse",
    backgroundColor: "white",
  },
  entriesCell: {
    position: "sticky",
    top: 0,
    textAlign: "center",
    paddingLeft: "12px",
    fontSize: "14px",
    padding: 6,
    backgroundColor: "#EDF2F7",
    borderBottom: "1px solid #A0AEC0",
    color: "rgb(85, 85, 85)",
    width: "250px",
    zIndex: 2,
  },
  runCell: {
    position: "sticky",
    top: 0,
    textAlign: "center",
    fontSize: "14px",
    padding: 6,
    backgroundColor: "#EDF2F7",
    borderBottom: "1px solid #A0AEC0",
    color: "rgb(85, 85, 85)",
    zIndex: 2,
  },
  entryCell: {
    // borderRight: "1px solid #CCC",
    backgroundColor: "white",
    fontWeight: "normal",
    // border: "1px solid #A0AEC0",
    // borderTop: "1px solid #E2E8F0",
    borderBottom: "1px solid #CBD5E0",
  },
  timingCell: {
    padding: 0,
    fontWeight: "normal",
    color: "black",
    // border: "1px solid #A0AEC0",
    borderLeft: "1px solid #CBD5E0",
    borderRight: "1px solid #CBD5E0",
    borderBottom: "1px solid #CBD5E0",
  },
  blankCell: {
    padding: 0,
    fontWeight: "normal",
    color: "black",
    // border: "1px solid #E2E8F0",
  },
};

// TimingTable.whyDidYouRender = true;

export default TimingTable;
