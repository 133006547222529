import { sortBy } from "lodash";
import { useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
} from "@chakra-ui/react";
import { nextEmptyNotQueuedRunForEntry } from "./utils";
import EntryForm from "../../event-detail/EntryForm";

export default function QueueOtherModal({
  eventId,
  entries,
  queuedRunLogs,
  isOpen,
  onClose,
  onSubmit,
  refetchEntries,
}) {
  const entryIdRef = useRef();
  const [showNewEntryForm, setShowNewEntryForm] = useState(false);

  const handleSubmit = (ev) => {
    const entry = entries.find((e) => e.id === entryIdRef.current.value);
    const run = nextEmptyNotQueuedRunForEntry(entry, queuedRunLogs);
    onSubmit(entry, run);
    onClose();
  };

  const handleNewEntryClick = () => {
    setShowNewEntryForm(true);
  };

  const handleNewEntryClose = () => {
    setShowNewEntryForm(false);
    onClose();
  };

  const onEntryFormMutate = (entry) => {
    const run = nextEmptyNotQueuedRunForEntry(entry, queuedRunLogs);
    onSubmit(entry, run);
    onClose();
    refetchEntries();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Assign Entry</ModalHeader>
        <ModalCloseButton />
        {!showNewEntryForm && (
          <>
            <ModalBody>
              <Select ref={entryIdRef} placeholder="Select option">
                {sortBy(entries, ["group_id"], ["asc"]).map((entry) => (
                  <option value={entry.id} key={entry.id}>
                    {entry.entry_id} - {entry.name} - {entry.class_short} -{" "}
                    {entry.class_modifier} - {entry.vehicle_color} -{" "}
                    {entry.vehicle}
                  </option>
                ))}
              </Select>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                Assign Selected Entry
              </Button>
              <Button variant="ghost" onClick={handleNewEntryClick}>
                Assign New Entry
              </Button>
            </ModalFooter>
          </>
        )}
        <EntryForm
          eventId={eventId}
          entries={entries}
          isOpen={showNewEntryForm}
          onClose={handleNewEntryClose}
          onMutate={onEntryFormMutate}
          // onSuccess={() => console.log("onSuccess")}
        />
      </ModalContent>
    </Modal>
  );
}
