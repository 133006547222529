import axios from "axios";

// test
export const baseUrl =
  process.env.REACT_APP_API_BASE_URL || "http://api.solotiming.test:3001";

const client = axios.create({
  baseURL: baseUrl,
  timeout: 30000,
  headers: {
    "content-type": "application/json",
    Accept: "application/json",
  },
  mode: "cors",
  withCredentials: true,
  credentials: "include",
});

export const api = {
  login: async (data) => await client.post("/login", { ...data }),
  logout: async (data) => await client.post("/logout", { ...data }),
  compClasses: async () => (await client.get("/comp_classes")).data,
  addEventSeries: async (data) =>
    (await client.post("/event_series", { event_series: { ...data } })).data,
  eventSeries: () => async () => (await client.get("/event_series")).data,
  eventsSeriesShow: (id) => async () =>
    (await client.get(`/event_series/${id}`)).data,
  deleteEventSeries: async (id) =>
    (await client.delete(`/event_series/${id}`)).data,
  updateEventSeries: async (seriesId, data) =>
    (await client.patch(`/event_series/${seriesId}`, { event_series: data }))
      .data,
  event: (id) => async () => (await client.get(`/events/${id}`)).data,
  createEvent: async (data) =>
    (await client.post("/events", { event: { ...data } })).data,
  updateRunGroupConfig: async (eventId, group, runCount) =>
    await client.patch(`/events/${eventId}/update_run_group_config`, {
      group: group,
      run_count: runCount,
    }),
  completeEvent: async (eventId) =>
    await client.patch(`/events/${eventId}/complete`),
  archiveEvent: async (eventId) =>
    await client.patch(`/events/${eventId}/archive`),
  eventEntries: (id) => async () =>
    (await client.get(`/events/${id}/entries`)).data,
  eventTimingEntries: (id) => async () =>
    (await client.get(`/events/${id}/timing_entries`)).data,
  eventRepublishResults: async (eventId) =>
    await client.post(`/events/${eventId}/republish_results`),
  events: (seriesId, page, itemsPerPage) => async () =>
    (
      await client.get(
        `/events?series_id=${seriesId}&page=${page}&items=${itemsPerPage}`
      )
    ).data,
  updateEvent: async (eventId, data) =>
    (await client.patch(`/events/${eventId}`, { event: data })).data,
  msrEvent: (msrEventId) => async () =>
    (await client.get(`/msr/events/${msrEventId}`)).data,
  msrEvents: async () => (await client.get("/msr/events")).data,
  indexFactorGroups: async () =>
    (await client.get("/index_factor_groups")).data,
  indexFactorGroup: (id) => async () =>
    (await client.get(`/index_factor_groups/${id}`)).data,
  organization: async () => (await client.get("/organizations/my")).data,
  msrSegmentAssignments: (msrEventId, msrSegmentId) => async () =>
    (
      await client.get(
        `/msr/events/${msrEventId}/segments/${msrSegmentId}/assignments`
      )
    ).data,
  msrEventSegments: (msrEventId) => async () =>
    (await client.get(`/msr/events/${msrEventId}/segments`)).data,
  importMsrEvent: async (
    msrEventId,
    msrSegmentIds,
    eventSeriesIds,
    groupIds,
    indexFactorGrouId
  ) =>
    (
      await client.post(`/msr/events/${msrEventId}/import`, {
        segment_ids: msrSegmentIds,
        event_series_ids: eventSeriesIds,
        group_ids: groupIds,
        index_factor_group_id: indexFactorGrouId,
      })
    ).data,
  reimportMsrEvent: async (msrEventId, msrSegmentIds) =>
    (
      await client.post(`/msr/events/${msrEventId}/import`, {
        segment_ids: msrSegmentIds,
        reimport: true,
      })
    ).data,
  deleteEvent: async (id) => (await client.delete(`/events/${id}`)).data,
  addEntry: async (eventId, data) =>
    (
      await client.post(`/events/${eventId}/entries`, {
        entry: { ...data, event_id: eventId },
      })
    ).data,
  updateEntry: async (eventId, entryId, data) =>
    (
      await client.patch(`/events/${eventId}/entries/${entryId}`, {
        entry: data,
      })
    ).data,
  deleteEntry: async (eventId, id) =>
    (await client.delete(`/events/${eventId}/entries/${id}`)).data,
  addClass: async (data) =>
    (await client.post("/comp_classes", { comp_class: { ...data } })).data,
  updateClass: async (classId, data) =>
    (await client.patch(`/comp_classes/${classId}`, { comp_class: data })).data,
  deleteClass: async (eventId, id) =>
    (await client.delete(`/comp_classes/${id}`)).data,
  classModifiers: async () => (await client.get("/class_modifiers")).data,
  addClassModifier: async (data) =>
    (await client.post("/class_modifiers", { class_modifier: { ...data } }))
      .data,
  updateClassModifier: async (classModifierId, data) =>
    (
      await client.patch(`/class_modifiers/${classModifierId}`, {
        class_modifier: data,
      })
    ).data,
  deleteClassModifier: async (id) =>
    (await client.delete(`/class_modifiers/${id}`)).data,
  runLogs: (eventId, group) => async () =>
    (await client.get(`/events/${eventId}/run_logs?group=${group}`)).data,
  createRunLog: async (eventId, data) =>
    (await client.post(`/events/${eventId}/run_logs`, { run_log: { ...data } }))
      .data,
  updateRunLog: async (eventId, runLogId, data) =>
    (
      await client.patch(`/events/${eventId}/run_logs/${runLogId}`, {
        run_log: data,
      })
    ).data,
};
