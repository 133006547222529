import { IconButton, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { debugUuid } from "../../../utils";
import PenaltyControls from "./PenaltyControls";

export default function QueuedRunLog({
  log,
  queueIdx,
  runIdx,
  updateRunLog,
  timingEnabled,
  event,
  debugEnabled,
  dequeueRunLog,
}) {
  const handleDequeue = () => dequeueRunLog(log);

  return (
    <div key={log.run_id} style={styles.queuedRun}>
      <div style={styles.leftControls}>
        <IconButton
          variant="link"
          icon={<CloseIcon color="tomato" fontSize={14} />}
          onClick={handleDequeue}
        />
      </div>
      <div style={styles.queuedRunLeft}>
        <p>
          <strong>{log?.entry?.entry_id}</strong> -{" "}
          <strong>{log?.entry?.name}</strong>
        </p>
        <p>
          {log?.entry.class_short} - {log?.entry.class_modifier}
        </p>
        <p>{log?.entry.vehicle_color}</p>
        <p>{log?.entry.vehicle}</p>
        {debugEnabled && <p>{debugUuid(log?.run_id)}</p>}
      </div>
      <div style={styles.queuedRunCenter}>
        <p
          style={{
            fontSize: 16,
            fontWeight: "normal",
            fontFamily: "monospace",
          }}
        >
          NEXT {queueIdx + 1}
        </p>
        <p>
          <Text color="tomato" style={{ fontWeight: "bold" }} fontSize={14}>
            {log?.data?.penaltyCount ? `+${log?.data?.penaltyCount}` : ""}
          </Text>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>R{runIdx + 1}</strong>
        </p>
        {debugEnabled && <p>{debugUuid(log?.run_id)}</p>}
      </div>
      <PenaltyControls
        runLog={log}
        updateRunLog={updateRunLog}
        timingEnabled={timingEnabled}
        event={event}
      />
    </div>
  );
}

const styles = {
  queuedRun: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FEFCBF",
    fontSize: 14,
  },
  leftControls: {
    paddingTop: 10,
    width: 40,
  },
  queuedRunLeft: {
    padding: 6,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: 210,
  },
  queuedRunCenter: {
    padding: 6,
    width: 80,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-between",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  penaltyControls: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },
  penaltyControlBtnLeft: {
    border: "1px solid #E2E8F0",
    height: "100%",
    flex: 1,
    color: "#CBD5E0",
  },
  penaltyControlBtnRight: {
    border: "1px solid #E2E8F0",
    borderLeft: "none",
    height: "100%",
    flex: 1,
    color: "#CBD5E0",
  },
  dnfControls: {
    color: "#CBD5E0",
    border: "1px solid #E2E8F0",
    borderTop: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    width: "100%",
  },
};
