import _ from "lodash";
import {
  Button,
  Card,
  CardBody,
  Flex,
  FormErrorMessage,
  Heading,
  Input,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { api } from "../../api";
import { validatePresent } from "../../validators";

export default function Login() {
  const history = useHistory();

  const toast = useToast();

  const handleSuccess = (data) => {
    toast({
      title: "Success",
      description: "Logged in",
      status: "success",
      duration: 6000,
      isClosable: true,
    });
    history.push("/events");
  };

  const loginMutation = useMutation((variables) => api.login(variables), {
    onSuccess: handleSuccess,
    // onMutate: onMutate, // TODO: OFFLINE
  });

  const isFormLoading = loginMutation.isLoading;

  const handleSubmit = (values) => {
    console.log("LOGGING IN", values);
    loginMutation.mutate(values);
  };

  return (
    <Flex
      bg="gray.200"
      style={{ height: "100vh" }}
      alignItems="center"
      justifyContent="center"
    >
      <Card width={500} bg="white" p={6}>
        <CardBody>
          <Heading as="h2" textAlign="center" mt={6} mb={12}>
            SoloTiming System
          </Heading>

          {/* <Text>View a summary of all your customers over the last month.</Text> */}
          <Formik initialValues={{}} onSubmit={handleSubmit}>
            {(props) => (
              <>
                {!isFormLoading && (
                  <Form>
                    <Field name="login" validate={validatePresent("login")}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.login && form.touched.login}
                          mb={6}
                        >
                          <FormLabel htmlFor="login">Email</FormLabel>
                          <Input
                            {...field}
                            id="login"
                            //   placeholder="full class name (eg: Super Street)"
                            autoComplete="off"
                            type="email"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.login}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="password"
                      validate={validatePresent("password")}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.password && form.touched.password
                          }
                          mb={6}
                        >
                          <FormLabel htmlFor="password">Password</FormLabel>
                          <Input
                            {...field}
                            id="password"
                            autoComplete="off"
                            type="password"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.password}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      colorScheme="blue"
                      type="submit"
                      onClick={props.handleSubmit}
                    >
                      Sign In
                    </Button>
                  </Form>
                )}
              </>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Flex>
  );
}
