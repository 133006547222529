import { Text, Badge, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { api } from "../../api";
import { useLoginOnUnauthorized } from "../../hooks";
import {
  Screen,
  TopControls,
  TopLeft,
  TopRight,
  Main,
  MainLeft,
  MainRight,
} from "../../components/template";
import Head from "../../components/Head";
import Pagy from "../../components/Pagy";
import Footer from "../../components/Footer";
import LoadingScreen from "../../components/LoadingScreen";
import EventActionsMenu from "../../components/EventActionsMenu";
import NewEventButton from "../../components/NewEventButton";
import EventsSidebar from "./EventsSidebar";

const colorScheme = {
  created: "yellow",
  completed: "green",
  archived: null,
};

function Events() {
  const handleUnauthorizedError = useLoginOnUnauthorized();

  let params = useParams();
  let { id, page, items } = params;
  id ||= "";
  page ||= 1;
  items ||= 10;

  const {
    isLoading,
    data,
    error,
    refetch: refetchEvents,
  } = useQuery(`events-${id}-${page}-${items}`, api.events(id, page, items), {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: handleUnauthorizedError,
  });

  const events = data ? data.data : [];

  if (isLoading || error) return <LoadingScreen />;

  return (
    <Screen>
      <Head title="Events" />
      <TopControls>
        <TopLeft />
        <TopRight>
          <NewEventButton />
        </TopRight>
      </TopControls>
      <Main>
        <MainLeft>
          <EventsSidebar />
        </MainLeft>
        <MainRight>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Event</Th>
                <Th>Series</Th>
                <Th>Date</Th>
                <Th>Venue</Th>
                <Th>Status</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {events.map((event) => (
                <Tr key={`event-${event.id}`}>
                  <Td>
                    <Link to={`/events/${event.id}`}>
                      <Text as="span" color="blue.500">
                        {event.name}
                      </Text>
                    </Link>
                  </Td>
                  <Td>
                    {event.series.map((series) => (
                      <p>
                        <Link
                          to={`/series-events/${series.id}/page/1/items/10`}
                        >
                          <Text as="span" color="blue.500">
                            <Text as="span">{series.name}</Text>
                          </Text>
                        </Link>
                      </p>
                    ))}
                  </Td>
                  <Td>{event.start_date}</Td>
                  <Td>{event.venue}</Td>
                  <Td>
                    <Badge colorScheme={colorScheme[event.state]}>
                      {event.state}
                    </Badge>
                  </Td>
                  <Td>
                    <EventActionsMenu
                      event={event}
                      refetchEvents={refetchEvents}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagy pagy={data.pagy} />
        </MainRight>
      </Main>
      <Footer />
    </Screen>
  );
}

export default Events;
