import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import {
  Button,
  Select,
  FormControl,
  FormLabel,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Heading,
  Progress,
} from "@chakra-ui/react";
import { api } from "../../api";

export default function SeriesSelect({ close, updateEvent }) {
  const selectRef = useRef();

  useEffect(() => {
    console.log("setting series select shown");
  }, []);

  const { isLoading, data, error } = useQuery(
    "active-series",
    api.eventSeries(),
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const series = data ? data : [];

  const handleNext = () => {
    updateEvent({
      event_series_id: selectRef.current.value,
      event_series_name: series.find((s) => s.id === selectRef.current.value)
        ?.name,
    });
  };

  return (
    <ModalContent>
      <ModalHeader>Import MSR event</ModalHeader>

      {!isLoading && (
        <>
          <ModalBody>
            <FormControl>
              <FormLabel>Is this event part of a series?</FormLabel>
              <Select ref={selectRef} placeholder="Select a series">
                {series.map((s) => (
                  <option key={`series-${s.id}`} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={close} ml={3}>
              Close
            </Button>
            <Button colorScheme="blue" onClick={handleNext} ml={3}>
              Next
            </Button>
          </ModalFooter>
        </>
      )}
      {isLoading && (
        <ModalBody>
          {/* <Heading size="sm" pb={4}>
            Importing segments from MSR...
          </Heading> */}
          <Progress size="xs" isIndeterminate my={8} />
        </ModalBody>
      )}
      {error && (
        <ModalBody>
          <Heading size="sm" pb={4} color="red.500">
            Unable to import MSR event segments. Please try again in a minute.
          </Heading>
        </ModalBody>
      )}
    </ModalContent>
  );
}
