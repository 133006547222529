/*
# Scenarios

| | | |    |X| | |
| | | | -> | | | |
| | | |    | | | |

|0| | |    |0| | |
| | | | -> |X| | |
| | | |    | | | |

|0| | |    |0| | |
|0| | | -> |0| | |
| | | |    |X| | |

|0| | |    |0|X| |
|0| | | -> |0| | |
|0| | |    |0| | |

|X| | |    |0| | |
| | | | -> |X| | |
| | | |    | | | |

|0| | |    |0| | |
|X| | | -> |0| | |
| | | |    |X| | |

|0| | |    |0|X| |
|0| | | -> |0| | |
|X| | |    |0| | |

|0|X| |    |0|0| |
| | | | -> | |X| |
| | | |    | | | |

|X| | |    |0|X| |
|0| | | -> |0| | |
|0| | |    |0| | |

|0| | |    |0|X| |
|X| | | -> |0| | |
|0| | |    |0| | |

|0|0|0|    |0|0|0|
|0|0|0| -> |0|0|0|
|0|0|X|    |0|0|0|

*/

export function nextSelectedRun(runs, entries, selectedRun) {
  if (entries.length === 0) return [];
  if (selectedRun.length === 0) return [];

  const [currentEntryId, currentRunIdx] = selectedRun;

  const currentOrNextRunNumFilter = (run) => {
    const runIdx = run - 1;

    const isCurrentEntryFinalEntryInRunGroup =
      entries[entries.length - 1].entry_id === currentEntryId &&
      currentRunIdx === runIdx;

    const includeRun =
      (!currentRunIdx && currentRunIdx !== 0) ||
      (!isCurrentEntryFinalEntryInRunGroup && runIdx >= currentRunIdx);

    return includeRun;
  };

  const entriesFilter = (runIdx) => (entry) => {
    const isExistingSelectedRun = selectedRun.length > 0;

    const isSameRunGroupAsSelectedRun =
      selectedRun.length > 0
        ? selectedRun[0].substring(0, 1) === entry.entry_id.substring(0, 1)
        : true;

    const isCurrentEntryFinalEntryInRunGroup =
      entries[entries.length - 1].entry_id === currentEntryId;

    const isLaterThanCurrentEntryInRunGroup =
      currentEntryId !== entry.entry_id &&
      parseInt(entry.entry_id.substring(1)) >
        parseInt(currentEntryId ? currentEntryId.substring(1) : 0);

    const isEntryFromNextRunGroup = runIdx > currentRunIdx;

    return (
      (isSameRunGroupAsSelectedRun && !isExistingSelectedRun) ||
      isCurrentEntryFinalEntryInRunGroup ||
      isLaterThanCurrentEntryInRunGroup ||
      isEntryFromNextRunGroup
    );
  };

  let nextSelectedEntry;
  let nextSelectedRun = runs.filter(currentOrNextRunNumFilter).find((run) => {
    const runIdx = run - 1;
    const entry = entries.filter(entriesFilter(runIdx)).find((d) => {
      return (
        d.runs[runIdx] && !d.runs[runIdx].scratchTime && !d.runs[runIdx].dnf
      );
    });

    if (entry) nextSelectedEntry = entry.entry_id;

    return nextSelectedEntry;
  });

  return nextSelectedEntry && nextSelectedRun
    ? [nextSelectedEntry, nextSelectedRun - 1]
    : [];
}

export const scrollToCell = (obj) => {
  const center = -window.document.body.offsetHeight / 2;
  const topPadding = center;
  let curtop = topPadding;

  if (obj.offsetParent) {
    do {
      curtop += obj.offsetTop;
    } while ((obj = obj.offsetParent));

    document.getElementById("timing-container").scrollTo(0, curtop);
  }
};
