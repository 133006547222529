import { indexOf, isEmpty } from "lodash";
import { useMutation } from "react-query";
import { api } from "../../../api";
import { useToast } from "../../../hooks";
import { firstBlankRun } from "../../../utils";

export function runsSig(entry) {
  return entry.runs
    .map(
      (r) =>
        `id:${r.id}:scratchTime:${r.scratchTime}:dnf:${r.dnf}:penaltyCount:${r.penaltyCount}`
    )
    .join(":");
}

export function nextEntry(entries, queuedRunLogs, runLogs) {
  if (entries.length === 0) return { nextRunEntry: null, nextRun: null };

  let nextRunEntry = entries[0];
  let nextRun = entries[0].runs[0];
  let nextEntryIdx = null;
  const assignedFinishedRunLogs = runLogs.filter((l) => !isEmpty(l.entry));

  if (queuedRunLogs.length > 0) {
    nextEntryIdx =
      entries.indexOf(queuedRunLogs[queuedRunLogs.length - 1].entry) + 1;
  } else if (assignedFinishedRunLogs.length > 0) {
    const firstAssignedFinishedLog = assignedFinishedRunLogs[0];
    nextEntryIdx =
      entries.findIndex((e) => e.id === firstAssignedFinishedLog.entry.id) + 1;
  }

  if (nextEntryIdx) {
    if (nextEntryIdx === entries.length) {
      nextRunEntry = entries[0];
    } else {
      nextRunEntry = entries[nextEntryIdx];
    }
    nextRun = nextEmptyNotQueuedRunForEntry(nextRunEntry, queuedRunLogs);
  }

  return { nextRunEntry, nextRun };
}

export function nextEmptyNotQueuedRunForEntry(entry, queuedRunLogs) {
  const notQueuedRuns = entry.runs.filter(
    (r) => !queuedRunLogs.some((rl) => rl.run_id === r.id)
  );
  return firstBlankRun(notQueuedRuns);
}

export function useUpdateEntryMutation(queryClient, eventId, selectedRunGroup) {
  const { errorToast } = useToast();

  return useMutation(
    (variables) => api.updateEntry(eventId, variables.entry_id, variables),
    {
      onError: (err, d) => {
        errorToast("Unable to save run");
      },
      onMutate: async (updatedEntry) => {
        const entryQueryKey = `event-${eventId}-timing-entries`;
        // Cancel any outgoing refetches so they don't overwrite optimistic update
        await queryClient.cancelQueries(entryQueryKey);

        // Optimistically update to the new value
        queryClient.setQueryData(entryQueryKey, (prev) => {
          const indexOfEntry = prev.indexOf(
            prev.find((e) => e.id === updatedEntry.id)
          );
          prev[indexOfEntry] = { ...prev[indexOfEntry], ...updatedEntry };
          return prev;
        });

        const runLogQueryKey = `event-${eventId}-run-logs-${selectedRunGroup}`;

        // Cancel any outgoing refetches so they don't overwrite optimistic update
        await queryClient.cancelQueries(runLogQueryKey);

        queryClient.setQueryData(runLogQueryKey, (prev) => {
          return prev.map((log) => {
            const connectedRun = updatedEntry.runs.find(
              (r) => r.id === log.run_id
            );

            if (connectedRun) {
              let updatedLog = { ...log };
              return {
                ...updatedLog,
                data: {
                  dnf: connectedRun.dnf,
                  scratchTime: connectedRun.scratchTime,
                  penaltyCount: connectedRun.penaltyCount,
                },
              };
            } else {
              return log;
            }
          });
        });

        return { updatedEntry };
      },
    }
  );
}

export async function updateCacheForEntry(queryClient, event, entry, updates) {
  const queryKey = `event-${event.id}-timing-entries`;
  // Cancel any outgoing refetches so they don't overwrite optimistic update
  await queryClient.cancelQueries(queryKey);

  // Optimistically update to the new value
  queryClient.setQueryData(queryKey, (prev) => {
    return prev.map((e) => {
      if (e.id === entry.id) {
        return { ...e, ...updates };
      } else {
        return e;
      }
    });
  });
}

export function useCreateRunLogMutation(queryClient, event, selectedRunGroup) {
  const { errorToast } = useToast();

  return useMutation((variables) => api.createRunLog(event.id, variables), {
    onError: (err, d) => errorToast("Unable to save run log"),
    onSuccess: (result, variables, context) => {
      queryClient.setQueryData(
        `event-${event?.id}-run-logs-${selectedRunGroup}`,
        (old) =>
          old.map((log) =>
            log.uuid === context.optimisticRunLog.uuid ? result : log
          )
      );
    },
    onMutate: async (variables) => {
      const queryKey = `event-${event?.id}-run-logs-${selectedRunGroup}`;
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueryData(queryKey, (prev) => [variables, ...prev]);

      return { optimisticRunLog: variables };
    },
  });
}

export function useUpdateRunLogMutation(queryClient, event, selectedRunGroup) {
  const { errorToast } = useToast();

  return useMutation(
    (variables) =>
      api.updateRunLog(event.id, variables.run_log_id, variables.run_log),
    {
      onError: (err, d) => errorToast("Unable to update run log"),
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(
          `event-${event?.id}-run-logs-${selectedRunGroup}`,
          (old) =>
            old.map((log) =>
              log.uuid === context.optimisticRunLog.uuid ? result : log
            )
        );
      },
      onMutate: async (variables) => {
        const queryKey = `event-${event?.id}-run-logs-${selectedRunGroup}`;
        // Cancel any outgoing refetches so they don't overwrite optimistic update
        await queryClient.cancelQueries(queryKey);
        // Optimistically update to the new value
        queryClient.setQueryData(queryKey, (prev) => {
          const indexOfRunLog = prev.indexOf(
            prev.find((rl) => rl.uuid === variables.run_log.uuid)
          );
          prev[indexOfRunLog] = {
            ...prev[indexOfRunLog],
            ...variables.run_log,
          };
          return prev;
        });

        return { optimisticRunLog: variables.run_log };
      },
    }
  );
}
