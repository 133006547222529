import _ from "lodash";
import {
  Button,
  FormErrorMessage,
  Heading,
  Input,
  NumberInput,
  NumberInputField,
  Modal,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Progress,
  FormControl,
  FormLabel,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useMutation } from "react-query";
import { api } from "../../api";
import { validatePresent } from "../../validators";

export default function SeriesForm({
  initialSeries = {},
  isOpen,
  onClose,
  onMutate,
}) {
  const handleSuccess = (data) => {
    toast({
      title: "Success",
      description: `Series ${data.name} saved`,
      status: "success",
      duration: 6000,
      isClosable: true,
    });
    onClose();
    onMutate(data);
  };

  const toast = useToast();

  const createMutation = useMutation(
    (variables) => api.addEventSeries(variables),
    {
      onSuccess: handleSuccess,
      // onMutate: onMutate, // TODO: OFFLINE
    }
  );

  const updateMutation = useMutation(
    (variables) => api.updateEventSeries(initialSeries.id, variables),
    {
      onSuccess: handleSuccess,
      onError: (err) => {
        let errorDescription;
        if (err.response.headers["content-type"].includes("application/json")) {
          errorDescription = _(err.response.data || {})
            .toPairs()
            .flatMap()
            .join(" ");
        }

        toast({
          title: "Error",
          description: errorDescription || "Error saving series",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      },
    }
  );

  const isFormLoading = createMutation.isLoading || updateMutation.isLoading;

  const handleSubmit = (values) => {
    if (initialSeries.id) {
      updateMutation.mutate(values);
    } else {
      createMutation.mutate(values);
    }
  };

  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <Formik
          initialValues={_.pick(initialSeries, "name", "min_events")}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <>
              <ModalHeader>New Series</ModalHeader>
              {isFormLoading && (
                <ModalBody>
                  <Heading size="sm">
                    <Text>Saving</Text>
                    <Progress size="xs" isIndeterminate my={8} />
                  </Heading>
                </ModalBody>
              )}

              {!isFormLoading && (
                <ModalBody>
                  <Form>
                    <Field name="name" validate={validatePresent("name")}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                          mb={6}
                        >
                          <FormLabel htmlFor="name">Name</FormLabel>
                          <Input
                            {...field}
                            id="name"
                            placeholder="name of the series"
                            autoComplete="off"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="min_events"
                      validate={validatePresent("min_events")}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.min_events && form.touched.min_events
                          }
                          mb={6}
                        >
                          <FormLabel htmlFor="min_events">Min Events</FormLabel>
                          <Input
                            {...field}
                            id="min_events"
                            placeholder="minimum # of events to qualify for series"
                            autoComplete="off"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.min_events}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Form>
                </ModalBody>
              )}

              <ModalFooter>
                <Button onClick={onClose} ml={3}>
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  ml={3}
                  type="submit"
                  onClick={props.handleSubmit}
                >
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}
