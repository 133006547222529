import { Text } from "@chakra-ui/react";
import PenaltyControls from "./PenaltyControls";

export default function UnassignedRunLog({
  log,
  onOpen,
  updateRunLog,
  timingEnabled,
  event,
}) {
  return (
    <div key={log.run_id} style={styles.unassignedRun}>
      <div onClick={onOpen} style={styles.queuedRunLeft}>
        <p
          style={{
            color: "#38A169",
            height: "100%",
            flex: 1,
            paddingTop: 38,
            textAlign: "right",
          }}
        >
          <strong>+ ASSIGN ENTRY</strong>
        </p>
      </div>
      <div style={styles.queuedRunCenter}>
        <p
          style={{
            textAlign: "right",
            fontSize: 16,
            fontWeight: "normal",
            fontFamily: "monospace",
          }}
        >
          {log?.data?.scratchTime}
        </p>
        <p
          style={{
            textAlign: "right",
          }}
        >
          <Text color="tomato" style={{ fontWeight: "bold" }} fontSize={14}>
            {log?.data?.penaltyCount ? `+${log?.data?.penaltyCount}` : ""}
          </Text>
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
      <PenaltyControls
        runLog={log}
        updateRunLog={updateRunLog}
        timingEnabled={timingEnabled}
        event={event}
      />
    </div>
  );
}

const styles = {
  unassignedRun: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    fontSize: 14,
  },
  queuedRunLeft: {
    height: "100%",
    cursor: "pointer",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "red",

    height: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "space-between",
    width: 210,
    padding: 6,
    marginLeft: 44,
  },
  queuedRunCenter: {
    padding: 6,
    width: 80,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-between",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
};
