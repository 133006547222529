import { Card, CardBody, CardHeader, Heading } from "@chakra-ui/react";

export default function MainRight({ children, heading = "" }) {
  return (
    <Card minHeight="100vh" bg="white" p={6} mt={6} flex="1">
      {heading && (
        <CardHeader>
          <Heading size="md">{heading}</Heading>
        </CardHeader>
      )}
      <CardBody>{children}</CardBody>
    </Card>
  );
}
