import { Text } from "@chakra-ui/react";
import { debugUuid } from "../../../utils";
import PenaltyControls from "./PenaltyControls";

export default function FinishedRunLog({
  idx,
  log,
  updateRunLog,
  timingEnabled,
  event,
  debugEnabled,
}) {
  return (
    <div key={log.run_id} style={styles.finishedRun}>
      <div style={styles.finishedRunLeft}>
        <p>
          <strong>
            {log?.entry?.entry_id} - {log?.entry.class_short} -{" "}
            {log?.entry.class_modifier}
          </strong>
        </p>
        <p>{log?.entry?.name}</p>
        <p>{log?.entry.vehicle_color}</p>
        <p>{log?.entry.vehicle}</p>
        {debugEnabled && <p>{debugUuid(log?.run_id)}</p>}
      </div>
      <div style={styles.finishedRunCenter}>
        <p
          style={{
            textAlign: "right",
            fontSize: 16,
            fontWeight: "normal",
            fontFamily: "monospace",
          }}
        >
          <Text textDecoration={log?.data?.dnf ? "line-through" : "none"}>
            {log?.data?.scratchTime}
          </Text>
        </p>
        <p
          style={{
            textAlign: "right",
          }}
        >
          <Text color="tomato" style={{ fontWeight: "bold" }} fontSize={14}>
            {log?.data?.penaltyCount ? `+${log?.data?.penaltyCount}` : ""}
          </Text>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>R{idx + 1}</strong>
        </p>
      </div>
      <PenaltyControls
        runLog={log}
        updateRunLog={updateRunLog}
        timingEnabled={timingEnabled}
        event={event}
      />
    </div>
  );
}

const styles = {
  finishedRun: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    fontSize: 14,
    boxShadow: "2px 2px 2px #000000",
  },
  finishedRunLeft: {
    width: 210,
    padding: 6,
    marginLeft: 44,
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "space-between",
  },
  finishedRunCenter: {
    width: 80,
    padding: 6,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-between",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
};
