import { useRef, useState } from "react";
import _ from "lodash";
import {
  useToast,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Link,
} from "@chakra-ui/react";
import { useMutation } from "react-query";
import { api } from "../../api";

function EntriesTable({ entries, onEdit, refetchEntries }) {
  return (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th>Group</Th>
          <Th>Number</Th>
          <Th>Name</Th>
          <Th>Vehicle</Th>
          <Th>Class</Th>
          <Th>Modifier</Th>
          <Th>Segment</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {entries.map((entry) => (
          <Tr key={`entry-${entry.id}`}>
            <Td>{entry.group}</Td>
            <Td>{entry.entry_num}</Td>
            <Td>
              <Link to="#" onClick={() => onEdit(entry)}>
                <Text as="span" color="blue.500">
                  {entry.name}
                </Text>
              </Link>
              {/* <Flex justifyContent="space-between">{entry.name}</Flex> */}
            </Td>
            <Td>
              {entry.vehicle}
              {entry.vehicle_color ? ` - ${entry.vehicle_color}` : ""}
            </Td>
            <Td>{entry.class_short}</Td>
            <Td>{entry.class_modifier}</Td>
            <Td>{entry.segment?.name}</Td>
            <Td>
              <ActionsMenu
                entry={entry}
                refetchEntries={refetchEntries}
                onEdit={onEdit}
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

function ActionsMenu({ entry, refetchEntries, onEdit }) {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();
  const deleteEventMutation = useMutation(
    () => api.deleteEntry(entry.event_id, entry.id),
    {
      onSuccess: () => {
        refetchEntries();
        setIsOpen(false);
        toast({
          title: "Success",
          description: `Entry ${entry.name} successfully deleted`,
          status: "success",
          duration: 6000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: `Error deleting entry ${entry.name}`,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      },
    }
  );

  const onDelete = () => deleteEventMutation.mutate();
  const handleDelete = () => setIsOpen(true);
  const handleEdit = () => onEdit(entry);

  return (
    <>
      <Menu>
        <MenuButton>
          <strong>...</strong>
        </MenuButton>
        <MenuList>
          {/* {_.get(entry, "state") !== "archived" && ( */}
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          {/* )} */}
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </MenuList>
      </Menu>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete entry - {entry.name}
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure?{" "}
              <strong>
                This will delete all entry data including run times.
              </strong>{" "}
              You cannot undo this action.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

// EntriesTable.whyDidYouRender = true;

export default EntriesTable;
