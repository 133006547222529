import { get } from "lodash";
import React, { createRef, useRef } from "react";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import { Button, IconButton } from "@chakra-ui/react";

export default function PenaltyControls({
  runLog,
  updateRunLog,
  timingEnabled,
  event,
}) {
  const editable = timingEnabled && get(event, "state") !== "archived";

  //   useEffect(() => {
  //     if (cellRef && cellRef.current.value !== runLog.scratchTime) {
  //       cellRef.current.value = runLog.scratchTime;
  //     }
  //   }, [entry.id, runsSig]);

  const dnfBtnRef = useRef(createRef());
  const addPenaltyBtnRef = useRef(createRef());
  const subtractPenaltyBtnRef = useRef(createRef());

  const updateRun = (data) => {
    updateRunLog(runLog, data);
  };

  const handleDnfClick = (ev) => {
    if (!editable) return;
    updateRun({ dnf: !runLog.data.dnf });
    dnfBtnRef.current.blur();
  };

  const handlePenaltyAdd = (ev) => {
    if (!editable) return;
    updateRun({ penaltyCount: parseInt(runLog.data.penaltyCount || 0) + 1 });
    addPenaltyBtnRef.current.blur();
  };

  const handlePenaltySubtract = (ev) => {
    if (!editable) return;
    const existingCount = parseInt(runLog.data.penaltyCount || 0);
    updateRun({ penaltyCount: existingCount > 0 ? existingCount - 1 : 0 });
    subtractPenaltyBtnRef.current.blur();
  };

  return (
    <div
      style={{
        ...styles.finishedRunRight,
        visibility: editable || runLog.data.dnf ? "visible" : "hidden",
      }}
    >
      <div style={styles.penaltyControls}>
        <IconButton
          visibility={editable ? "visible" : "hidden"}
          style={styles.penaltyControlBtnRight}
          ref={subtractPenaltyBtnRef}
          icon={<MinusIcon />}
          borderRadius="0px"
          onClick={handlePenaltySubtract}
        ></IconButton>
        <IconButton
          visibility={editable ? "visible" : "hidden"}
          style={styles.penaltyControlBtnLeft}
          ref={addPenaltyBtnRef}
          icon={<AddIcon />}
          borderRadius="0px"
          onClick={handlePenaltyAdd}
        ></IconButton>
      </div>
      <div style={styles.dnfControls}>
        <Button
          visibility={editable || runLog.data.dnf ? "visible" : "hidden"}
          color={runLog.data.dnf ? "red.400" : "gray.300"}
          style={styles.dnfBtn}
          borderRadius="0px"
          onClick={handleDnfClick}
          ref={dnfBtnRef}
        >
          DNF
        </Button>
      </div>
    </div>
  );
}

const styles = {
  finishedRunRight: {
    width: 60,
    // height: 80,
    // height: "100%",
    height: 100,
    // height: "100%",
    // backgroundColor: "#F7FAFC",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  penaltyControls: {
    // height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },
  penaltyControlBtnLeft: {
    minWidth: 30,
    width: 30,
    fontSize: 10,
    border: "1px solid #E2E8F0",
    height: "100%",
    flex: 1,
    color: "#CBD5E0",
  },
  penaltyControlBtnRight: {
    minWidth: 30,
    width: 30,
    fontSize: 10,
    border: "1px solid #E2E8F0",
    borderLeft: "none",
    height: "100%",
    flex: 1,
    color: "#CBD5E0",
  },
  dnfControls: {
    color: "#CBD5E0",
    border: "1px solid #E2E8F0",
    borderTop: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    width: "100%",
  },
  dnfBtn: {
    fontSize: 14,
    height: "100%",
  },
};
