import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Modal, ModalOverlay, useToast } from "@chakra-ui/react";
import EventSelect from "./EventSelect";
import Loading from "./Loading";
import Review from "./Review";
import GroupsSelect from "./GroupsSelect";
import SegmentsSelect from "./SegmentsSelect";
import SeriesSelect from "./SeriesSelect";
import { api } from "../../api";
import IndexFactorGroupSelect from "./IndexFactorGroupSelect";

export default function ImportMsrEvent({
  isOpen,
  close,
  msrEvent: existingMsrEvent,
  event,
}) {
  const history = useHistory();
  const toast = useToast();
  const [isDisabled, setIsDisabled] = useState(false);
  const [msrEvent, setMsrEvent] = useState(existingMsrEvent);
  const [selectedMsrSegments, setSelectedMsrSegments] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectSeriesShown, setSelectSeriesShown] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [selectedPaxConfig, setSelectedPaxConfig] = useState(null);

  // TODO: FIGURE HOW HOW TO LOAD MSR EVENT TO PREPOPULATE WHEN EVENT PROP PASSED!!!

  const importEventMutation = useMutation(
    (variables) =>
      api.importMsrEvent(
        variables.event_id,
        variables.segment_ids,
        variables.event_series_ids,
        variables.group_ids,
        variables.index_factor_group_id
      ),
    {
      onSuccess: (params) => {
        toast({
          title: "Success",
          description: "Event imported successfully",
          status: "success",
          duration: 6000,
          isClosable: true,
        });
        close();
        history.push(`/events/${params.id}`);
      },
    }
  );

  const handleSeriesSelectFinish = (seriesIds) => {
    setSelectedSeries(seriesIds);
    setSelectSeriesShown(true);
  };

  const handlePaxConfigSelectFinish = (groupId) => {
    setSelectedPaxConfig(groupId);
  };

  useEffect(() => {
    setIsDisabled(importEventMutation.isLoading);
  }, [importEventMutation.isLoading]);

  const importEvent = () => {
    importEventMutation.mutate({
      event_id: msrEvent.id,
      segment_ids: selectedMsrSegments.map((s) => s.id),
      group_ids: selectedGroups.map((s) => s.id),
      event_series_ids: selectedSeries,
      index_factor_group_id: selectedPaxConfig,
    });
  };

  const showEventSelect = !msrEvent;
  const showSegmentsSelect = msrEvent && selectedMsrSegments.length === 0;
  const showGroupsSelect =
    !showEventSelect && !showSegmentsSelect && selectedGroups.length === 0;
  const showSeriesSelect =
    !showEventSelect &&
    !showSegmentsSelect &&
    !showGroupsSelect &&
    !existingMsrEvent &&
    !selectSeriesShown;
  const showIndexSelect =
    !showEventSelect &&
    !showSegmentsSelect &&
    !showGroupsSelect &&
    !existingMsrEvent &&
    !showSeriesSelect &&
    !selectedPaxConfig;
  const showReview =
    msrEvent &&
    !showEventSelect &&
    !showSegmentsSelect &&
    !showGroupsSelect &&
    !showSeriesSelect &&
    !showIndexSelect &&
    !showIndexSelect &&
    !importEventMutation.isLoading;

  return (
    <Modal
      onClose={close}
      size="xl"
      isOpen={isOpen}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      {showEventSelect && (
        <EventSelect
          close={close}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          setMsrEvent={setMsrEvent}
          msrEvent={msrEvent}
        />
      )}
      {showSegmentsSelect && (
        <SegmentsSelect
          close={close}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          msrEvent={msrEvent}
          setMsrEvent={setMsrEvent}
          selectedMsrSegments={selectedMsrSegments}
          setSelectedMsrSegments={setSelectedMsrSegments}
        />
      )}
      {showGroupsSelect && (
        <GroupsSelect
          close={close}
          event={event}
          msrEvent={msrEvent}
          selectedMsrSegments={selectedMsrSegments}
          setSelectedGroups={setSelectedGroups}
        />
      )}
      {showSeriesSelect && (
        <SeriesSelect
          close={close}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          onFinish={handleSeriesSelectFinish}
        />
      )}
      {showIndexSelect && (
        <IndexFactorGroupSelect
          close={close}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          onFinish={handlePaxConfigSelectFinish}
        />
      )}
      {showReview && (
        <Review
          close={close}
          event={event}
          msrEvent={msrEvent}
          selectedMsrSegments={selectedMsrSegments}
          selectedGroups={selectedGroups}
          selectedPaxConfig={selectedPaxConfig}
          selectedSeries={selectedSeries}
          importEvent={importEvent}
          error={importEventMutation.error}
        />
      )}
      {importEventMutation.isLoading && <Loading />}
    </Modal>
  );
}
