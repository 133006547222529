import { useRef, useState } from "react";
import { useMutation } from "react-query";
import _ from "lodash";
import { Link } from "react-router-dom";
import {
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Badge,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Thead,
  Tbody,
  Text,
  Th,
  Tr,
  Td,
} from "@chakra-ui/react";
import { api } from "../../api";

function ClassesTable({ classes, onClick, onMutate }) {
  return (
    <Table size="sm" variant="simple">
      <Thead>
        <Tr>
          <Th top={101}>Short Name</Th>
          <Th top={101}>Long Name</Th>
          <Th textAlign="center">Linked to</Th>
          <Th top={101} textAlign="center">
            State
          </Th>
          <Th top={101} textAlign="right"></Th>
        </Tr>
      </Thead>
      <Tbody>
        {classes.map((c) => (
          <Tr key={`class-${c.id}`}>
            <Td>
              <Flex justifyContent="space-between">{c.short_name}</Flex>
            </Td>
            <Td>
              {c.state === "archived" ? (
                <Text as="span">{c.long_name}</Text>
              ) : (
                <Link to="#" onClick={() => onClick(c)}>
                  <Text as="span" color="blue.500">
                    {c.long_name}
                  </Text>
                </Link>
              )}
            </Td>
            <Td textAlign="center">{c.global_configuration?.key || "-"}</Td>
            <Td textAlign="center">
              <Badge>{c.state}</Badge>
            </Td>
            <Td textAlign="right">
              <ActionsMenu theClass={c} onEdit={onClick} onMutate={onMutate} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

function ActionsMenu({ theClass, onMutate, onEdit }) {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();
  const deleteEventMutation = useMutation(
    () => api.deleteClass(theClass.event_id, theClass.id),
    {
      onSuccess: () => {
        onMutate();
        setIsOpen(false);
        toast({
          title: "Success",
          description: `Class ${theClass.long_name} successfully archived`,
          status: "success",
          duration: 6000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: `Error deleting class ${theClass.long_name}`,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      },
    }
  );

  const onDelete = () => deleteEventMutation.mutate();
  const handleDelete = () => setIsOpen(true);
  const handleEdit = () => onEdit(theClass);

  return (
    theClass.state !== "archived" && (
      <>
        <Menu>
          <MenuButton>
            <strong>...</strong>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </MenuList>
        </Menu>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => setIsOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete class - {theClass.long_name}
              </AlertDialogHeader>
              <AlertDialogBody>
                Are you sure? <strong>This will delete all class data.</strong>{" "}
                You cannot undo this action.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={onDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  );
}

// ClassesTable.whyDidYouRender = true;

export default ClassesTable;
