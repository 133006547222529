import _ from "lodash";
import {
  Button,
  FormErrorMessage,
  Heading,
  Input,
  Modal,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Progress,
  FormControl,
  FormLabel,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useMutation } from "react-query";
import { api } from "../api";
import { validatePresent } from "../validators";
import { useClassModifiers, useCompClasses } from "../hooks";

export default function AddEntry({
  eventId,
  entry,
  entries,
  isOpen,
  onClose,
  onMutate,
}) {
  const { isLoading: isCompClassesLoading, data: classesData } =
    useCompClasses();
  const { isLoading: isClassModifiersLoading, data: modifiersData } =
    useClassModifiers();
  const isLoading = isCompClassesLoading || isClassModifiersLoading;

  const classes = _(classesData ? classesData : [])
    .orderBy(["short_name"], ["asc"])
    .value();

  const entryModifiers = _(entries).map("class_modifier").uniq().sort().value();

  const orgModifiers = _(modifiersData ? modifiersData : [])
    .orderBy(["short_name"], ["asc"])
    // .map(m => m.short_name)
    .value();

  const classModifiers = _(orgModifiers || [])
    .merge(entryModifiers)
    .uniq()
    .value();

  // TODO: Fetch these from API
  const segments = _(entries)
    .map((e) => ({
      id: _.get(e, "segment.id"),
      name: _.get(e, "segment.name"),
    }))
    .uniqBy("id")
    .sortBy("segment.name")
    .value();

  // TODO: Fetch these from API
  const groups = _(entries).map("group").uniq().sort().value();

  const handleSuccess = (data) => {
    toast({
      title: "Success",
      description: `Entry ${data.entry_id} - ${data.name} created`,
      status: "success",
      duration: 6000,
      isClosable: true,
    });
    onClose();
    onMutate(data);
  };

  const toast = useToast();

  const addEntryMutation = useMutation(
    (variables) => api.addEntry(eventId, variables),
    {
      onSuccess: handleSuccess,
      // onMutate: onMutate, // TODO: OFFLINE
    }
  );

  const handleSubmit = (values) => addEntryMutation.mutate(values);

  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        {isLoading ? (
          <Progress></Progress>
        ) : (
          <Formik
            initialValues={_.pick(
              entry,
              "name",
              "group",
              "comp_class_id",
              "class_long",
              "class_modifier",
              "vehicle",
              "vehicle_color"
            )}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <>
                <ModalHeader>Add Entry</ModalHeader>
                {addEntryMutation.isLoading && (
                  <ModalBody>
                    <Heading size="sm">
                      <Text>Saving</Text>
                      <Progress size="xs" isIndeterminate my={8} />
                    </Heading>
                  </ModalBody>
                )}

                {!addEntryMutation.isLoading && (
                  <ModalBody>
                    <Form>
                      <Field name="name" validate={validatePresent("name")}>
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={form.errors.name && form.touched.name}
                            mb={6}
                          >
                            <FormLabel htmlFor="name">Name</FormLabel>
                            <Input
                              {...field}
                              id="name"
                              placeholder="name"
                              autoComplete="off"
                              isRequired
                            />
                            <FormErrorMessage>
                              {form.errors.name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="group" validate={validatePresent("group")}>
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={form.errors.group && form.touched.group}
                            mb={6}
                          >
                            <FormLabel htmlFor="group">Group</FormLabel>
                            <Select
                              {...field}
                              placeholder="Select group"
                              id="group"
                              isRequired
                            >
                              {groups.map((g) => (
                                <option key={`run-option-${g}`} value={g}>
                                  {g}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {form.errors.group}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="segment_id"
                        validate={validatePresent("segment_id")}
                      >
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.segment_id && form.touched.segment_id
                            }
                            mb={6}
                          >
                            <FormLabel htmlFor="segment_id">Segment</FormLabel>
                            <Select
                              {...field}
                              placeholder="Select segment"
                              id="segment_id"
                              isRequired
                            >
                              {segments.map((s) => (
                                <option
                                  key={`segment-option-${s.segment_id}`}
                                  value={s.id}
                                >
                                  {s.name}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {form.errors.segment_id}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="comp_class_id"
                        validate={validatePresent("comp_class_id")}
                      >
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.comp_class_id &&
                              form.touched.comp_class_id
                            }
                            mb={6}
                          >
                            <FormLabel htmlFor="comp_class_id">Class</FormLabel>
                            <Select
                              {...field}
                              placeholder="Select class"
                              id="comp_class_id"
                              isRequired
                            >
                              {classes.map((c) => (
                                <option key={`run-option-${c.id}`} value={c.id}>
                                  {c.long_name}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {form.errors.comp_class_id}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="class_modifier"
                        validate={validatePresent("class_modifier")}
                      >
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.class_modifier &&
                              form.touched.class_modifier
                            }
                            mb={6}
                          >
                            <FormLabel htmlFor="class_modifier">
                              Class Modifier (Category)
                            </FormLabel>
                            <Select
                              {...field}
                              placeholder="Select class modifier"
                              id="class_modifier"
                              isRequired
                            >
                              {classModifiers.map((m) => (
                                <option
                                  key={`run-option-${m}`}
                                  value={m.short_name}
                                >
                                  {m.long_name}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {form.errors.class_modifier}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="vehicle"
                        validate={validatePresent("vehicle")}
                      >
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.vehicle && form.touched.vehicle
                            }
                            mb={6}
                          >
                            <FormLabel htmlFor="vehicle">Vehicle</FormLabel>
                            <Input
                              {...field}
                              id="vehicle"
                              placeholder="vehicle"
                              autoComplete="off"
                              isRequired
                            />
                            <FormErrorMessage>
                              {form.errors.vehicle}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="vehicle_color"
                        validate={validatePresent("vehicle_color")}
                      >
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.vehicle_color &&
                              form.touched.vehicle_color
                            }
                            mb={6}
                          >
                            <FormLabel htmlFor="vehicle_color">
                              Vehicle color
                            </FormLabel>
                            <Input
                              {...field}
                              id="vehicle_color"
                              placeholder="Vehicle color"
                              autoComplete="off"
                              isRequired
                            />
                            <FormErrorMessage>
                              {form.errors.vehicle_color}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Form>
                  </ModalBody>
                )}
                <ModalFooter>
                  <Button onClick={onClose} ml={3}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme="blue"
                    ml={3}
                    type="submit"
                    onClick={props.handleSubmit}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </>
            )}
          </Formik>
        )}
      </ModalContent>
    </Modal>
  );
}
