import _ from "lodash";

export const updateCacheForEntry = async (
  queryClient,
  eventId,
  entry,
  setFilteredEntries
) => {
  const queryKey = `event-${eventId}-timing-entries`;

  // Cancel any outgoing refetches so they don't overwrite optimistic update
  await queryClient.cancelQueries(queryKey);

  // Optimistically update to the new value
  queryClient.setQueryData(queryKey, (prev) => {
    const indexOfEntry = prev.indexOf(prev.find((e) => e.id === entry.id));
    prev[indexOfEntry] = { ...prev[indexOfEntry], ...entry };
    prev = prev.map((e) => {
      let newEntryNum;
      let newGroup;
      if (entry.swap_entry_id && e.id === entry.id) {
        let found = prev.find((p) => p.id === parseInt(entry.swap_entry_id));
        newEntryNum = found.entry_num;
        newGroup = found.group;
      } else if (
        entry.swap_entry_id &&
        parseInt(entry.swap_entry_id) === e.id
      ) {
        let found = prev.find((p) => p.id === parseInt(entry.id));
        newEntryNum = found.entry_num;
        newGroup = found.group;
      } else {
        newEntryNum = e.entry_num;
        newGroup = e.group;
      }

      return {
        ...e,
        entry_num: newEntryNum,
        group: newGroup,
        entry_id: `${newGroup}${newEntryNum}`,
      };
    });
    // setFilteredEntries(_.orderBy(prev, ["group", "entry_num"], ["asc", "asc"]));
    return prev;
  });

  return { newEntry: entry };
};

export const updateCacheForNewEntry = async (
  queryClient,
  eventId,
  entry
  // setFilteredEntries
) => {
  const queryKey = `event-${eventId}-timing-entries`;

  // Cancel any outgoing refetches so they don't overwrite optimistic update
  await queryClient.cancelQueries(queryKey);

  // Optimistically update to the new value
  queryClient.setQueryData(queryKey, (prev) => {
    entry.entry_id = `${entry.group}${entry.entry_num}`;
    prev.push(entry);
    // setFilteredEntries(_.orderBy(prev, ["group", "entry_num"], ["asc", "asc"]));
    return prev;
  });

  return { newEntry: entry };
};
