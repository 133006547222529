import _ from "lodash";
import {
  useToast,
  Button,
  FormErrorMessage,
  Heading,
  Input,
  Modal,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Progress,
  FormControl,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useQuery, useMutation } from "react-query";
import { api } from "../../api";
import { validatePresent } from "../../validators";
import { useClassModifiers, useCompClasses } from "../../hooks";

function EntryForm({
  eventId,
  entry = {},
  entries,
  isOpen,
  onClose,
  onMutate,
  onSuccess,
}) {
  const toast = useToast();

  const { isLoading: isCompClassesLoading, data: classesData } =
    useCompClasses();
  const { isLoading: isClassModifiersLoading, data: modifiersData } =
    useClassModifiers();

  const classes = _(classesData ? classesData : [])
    .orderBy(["short_name"], ["asc"])
    .value();

  const orgModifiers = _(modifiersData ? modifiersData : [])
    .orderBy(["short_name"], ["asc"])
    .value();

  // const entryModifiers = _(entries).map("class_modifier").uniq().sort().value();

  const classModifiers = _(orgModifiers || [])
    .filter((m) => m.state === "active")
    // .merge(entryModifiers)
    .sortBy("long_name")
    .uniq()
    .value();

  const handleSuccess = (entry) => {
    toast({
      title: "Success",
      description: "Entry successfully updated",
      status: "success",
      duration: 6000,
      isClosable: true,
    });
    if (onSuccess) onSuccess();
    onClose(0);
    if (onMutate) onMutate(entry);
  };

  const createMutation = useMutation(
    (variables) => api.addEntry(eventId, variables),
    {
      onSuccess: handleSuccess,
      onError: (err) => {
        let errorDescription;
        if (err.response.headers["content-type"].includes("application/json")) {
          errorDescription = _(err.response.data || {})
            .toPairs()
            .flatMap()
            .join(" ");
        }

        toast({
          title: "Error",
          description: errorDescription || "Error creating entry",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      },
    }
  );

  const updateMutation = useMutation(
    (variables) => api.updateEntry(eventId, entry.id, variables),
    {
      onSuccess: handleSuccess,
      onError: (err) => {
        let errorDescription;
        if (err.response.headers["content-type"].includes("application/json")) {
          errorDescription = _(err.response.data || {})
            .toPairs()
            .flatMap()
            .join(" ");
        }

        toast({
          title: "Error",
          description: errorDescription || "Error updating entry",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      },
    }
  );

  const isFormLoading = createMutation.isLoading || updateMutation.isLoading;

  const handleSubmit = (values) => {
    if (entry.id) {
      updateMutation.mutate(values);
    } else {
      createMutation.mutate(values);
    }
  };

  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <Formik
          initialValues={_.pick(
            entry,
            "id",
            "name",
            "group",
            "entry_num",
            "comp_class_id",
            "class_modifier",
            "vehicle",
            "vehicle_color"
          )}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <>
              <ModalHeader>New Entry</ModalHeader>
              {isFormLoading && (
                <ModalBody>
                  <Heading size="sm">
                    <Text>Saving</Text>
                    <Progress size="xs" isIndeterminate my={8} />
                  </Heading>
                </ModalBody>
              )}

              {!isFormLoading && (
                <ModalBody>
                  <Form id="edit-entry-form">
                    <Field name="name" validate={validatePresent("name")}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                          mb={6}
                        >
                          <FormLabel htmlFor="name">Name</FormLabel>
                          <Input
                            {...field}
                            id="name"
                            placeholder="name"
                            autoComplete="off"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="group" validate={validatePresent("group")}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.group && form.touched.group}
                          mb={6}
                        >
                          <FormLabel htmlFor="group">Group</FormLabel>
                          <Input
                            {...field}
                            id="group"
                            placeholder="Group"
                            autoComplete="off"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.group}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="entry_num"
                      validate={validatePresent("entry_num")}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.entry_num && form.touched.entry_num
                          }
                          mb={6}
                        >
                          <FormLabel htmlFor="entry_num">#</FormLabel>
                          <Input
                            {...field}
                            id="entry_num"
                            placeholder="#"
                            autoComplete="off"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.entry_num}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="comp_class_id"
                      validate={validatePresent("comp_class_id")}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.comp_class_id &&
                            form.touched.comp_class_id
                          }
                          mb={6}
                        >
                          <FormLabel htmlFor="comp_class_id">Class</FormLabel>
                          <Select
                            {...field}
                            placeholder="Select class"
                            id="comp_class_id"
                            isRequired
                          >
                            {classes.map((c) => (
                              <option key={`comp-class-${c.id}`} value={c.id}>
                                {c.short_name} - {c.long_name}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.comp_class_id}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="class_modifier"
                      validate={validatePresent("class_modifier")}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.class_modifier &&
                            form.touched.class_modifier
                          }
                          mb={6}
                        >
                          <FormLabel htmlFor="class_modifier">
                            Class Modifier (Category)
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="Select class modifier"
                            id="class_modifier"
                            isRequired
                          >
                            {classModifiers.map((m) => (
                              <option
                                key={`run-option-${m.short_name}`}
                                value={m.short_name}
                              >
                                {m.long_name}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.class_modifier}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="vehicle" validate={validatePresent("vehicle")}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.vehicle && form.touched.vehicle
                          }
                          mb={6}
                        >
                          <FormLabel htmlFor="vehicle">Vehicle</FormLabel>
                          <Input
                            {...field}
                            id="vehicle"
                            placeholder="vehicle"
                            autoComplete="off"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.vehicle}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="vehicle_color"
                      validate={validatePresent("vehicle_color")}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.vehicle_color &&
                            form.touched.vehicle_color
                          }
                          mb={6}
                        >
                          <FormLabel htmlFor="vehicle_color">
                            Vehicle color
                          </FormLabel>
                          <Input
                            {...field}
                            id="vehicle_color"
                            placeholder="Vehicle color"
                            autoComplete="off"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.vehicle_color}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Form>
                </ModalBody>
              )}
              <ModalFooter>
                <Button onClick={onClose} ml={3}>
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  ml={3}
                  type="submit"
                  onClick={props.handleSubmit}
                  form="edit-entry-form"
                >
                  Update
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}

export default EntryForm;
