import { useMutation } from "react-query";
import {
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Button,
  Flex,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import SeriesFormButton from "./SeriesFormButton";
import { api } from "../../api";
import { DeleteIcon } from "@chakra-ui/icons";

function SeriesSidebar({ series, refetchEventSeries }) {
  let history = useHistory();

  const toast = useToast();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const cancelRef = useRef();
  const onDelete = () => deleteEventMutation.mutate();
  const deleteEventMutation = useMutation(
    () => api.deleteEventSeries(series.id),
    {
      onSuccess: () => {
        history.push("/events");
        setIsDeleteOpen(false);
        toast({
          title: "Success",
          description: `Series ${series.name} successfully deleted`,
          status: "success",
          duration: 6000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: `Error deleting series ${series.name}`,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      },
    }
  );

  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        pt={5}
      >
        <Heading as="h5" size="sm" color="gray.500">
          DETAILS
        </Heading>
        <SeriesFormButton
          label="Edit"
          onMutate={refetchEventSeries}
          initialSeries={series}
        />
      </Flex>
      <Box spacing={0}>
        <Stat mt={6}>
          <StatLabel>Min Events</StatLabel>
          <StatNumber style={{ fontSize: 16 }}>
            {series.min_events || "-"}
          </StatNumber>
        </Stat>
        <Stat mt={6}>
          <StatLabel>Min Events</StatLabel>
          <StatNumber style={{ fontSize: 16 }}>
            {series.min_events || "-"}
          </StatNumber>
        </Stat>
        <Stat mt={6}>
          <StatLabel>Min Events</StatLabel>
          <StatNumber style={{ fontSize: 16 }}>
            {series.min_events || "-"}
          </StatNumber>
        </Stat>
      </Box>
      <Button
        leftIcon={<DeleteIcon />}
        onClick={() => setIsDeleteOpen(true)}
        colorScheme="red"
        width="100%"
        mt={40}
        mr={4}
        variant="outline"
      >
        Delete
      </Button>
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete series - {series.name}
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? <strong></strong>Events belonging to this series
              will not be deleted but they will no longer be associated with the
              series. You cannot undo this action.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default SeriesSidebar;
