import _ from "lodash";
import {
  Alert,
  AlertIcon,
  Button,
  FormErrorMessage,
  Heading,
  Modal,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Progress,
  FormControl,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useMutation } from "react-query";
import { api } from "../../api";
import { validatePresent } from "../../validators";

export default function SwapEntry({
  entry,
  entries,
  isOpen,
  onClose,
  onMutate,
}) {
  const updateEntryMutation = useMutation(
    (variables) => api.updateEntry(entry.id, variables),
    {
      onSuccess: onClose,
      onMutate: onMutate,
    }
  );

  const entryOptions = entries.filter((e) => e.id !== entry.id);

  const handleSubmit = (values) => {
    updateEntryMutation.mutate(values);
  };

  const serverErrors = _.values(
    _.get(updateEntryMutation, "error.response.data", {})
  ).join(", ");

  return (
    <Modal size="md" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <Formik onSubmit={handleSubmit} initialValues={_.pick(entry, "id")}>
          {(props) => (
            <>
              <ModalHeader>Swap Entries</ModalHeader>
              {updateEntryMutation.isLoading && (
                <ModalBody>
                  <Heading size="sm">
                    <Text>Saving</Text>
                    <Progress size="xs" isIndeterminate my={8} />
                  </Heading>
                </ModalBody>
              )}

              {!updateEntryMutation.isLoading && (
                <ModalBody>
                  <Form>
                    <FormControl mb={6}>
                      <FormLabel htmlFor="swap_entry_id">Entry</FormLabel>
                      <Text>
                        {entry.entry_id} - {entry.name}
                      </Text>
                    </FormControl>
                    <Field
                      name="swap_entry_id"
                      validate={validatePresent("swap_entry_id")}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.swap_entry_id &&
                            form.touched.swap_entry_id
                          }
                          mb={6}
                        >
                          <FormLabel htmlFor="swap_entry_id">
                            Swap with
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="Select swap entry"
                            id="swap_entry_id"
                            isRequired
                          >
                            {entryOptions.map((e) => (
                              <option
                                key={`swap-entry-option-${e.id}`}
                                value={e.id}
                              >
                                {e.entry_id} - {e.name}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.swap_entry_id}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    {serverErrors && (
                      <Alert status="error" mt={4}>
                        <AlertIcon />
                        There was an error: {serverErrors}
                      </Alert>
                    )}
                  </Form>
                </ModalBody>
              )}
              <ModalFooter>
                <Button onClick={onClose} ml={3}>
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  ml={3}
                  type="submit"
                  onClick={props.handleSubmit}
                >
                  Update
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}
