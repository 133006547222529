import { Box, Center, Progress } from "@chakra-ui/react";

export default function LoadingScreen() {
  return (
    <Center height="100vh" width="100vw">
      <Box width="30%">
        <Progress size="md" isIndeterminate my={8} />
      </Box>
    </Center>
  );
}
