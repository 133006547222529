import { useEffect, useState } from "react";
import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Box, useToast } from "@chakra-ui/react";
import Head from "../../components/Head";
import Footer from "../../components/Footer";
import LoadingScreen from "../../components/LoadingScreen";
import TimingTable from "./TimingTable";
import Logger from "./logger/Logger";
import { api } from "../../api";
import { updateCacheForEntry, updateCacheForNewEntry } from "../../cache";
import { useCompClasses, useRunLogs } from "../../hooks";
import { useUpdateEntryMutation } from "./logger/utils";

function TimingSheet() {
  const toast = useToast();
  const history = useHistory();
  const params = useParams();
  const query = useSearchParamsQuery();
  const [loggerConnection, setLoggerConnection] = useState("");
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [timingEnabled, setTimingEnabled] = useState(false);
  const [debugEnabled, setDebugEnabled] = useState(false);
  const [queuedRunLogs, setQueuedRunLogs] = useState([]);
  const { id: eventId } = params;

  const dequeueRunLog = (log) => {
    const updatedLogs = queuedRunLogs.filter((l) => l.uuid !== log.uuid);
    setQueuedRunLogs(updatedLogs);

    const updatedRuns = log.entry.runs.map((r) => {
      if (r.id == log.run_id) {
        return {
          scratchTime: null,
          dnf: false,
          penaltyCount: 0,
          id: log.run_id,
        };
      } else {
        return r;
      }
    });

    // TODO: Figure out why this isn't clearing timing cell!!!
    // console.log("UPDATED RUNS", updatedRuns);
    updateCacheForUpdatedEntry({ ...log.entry, runs: updatedRuns });
    // TODO: Figure out why this isn't clearing timing cell!!!
  };

  const queryClient = useQueryClient();

  const handleGroupChange = (group) =>
    group
      ? history.push(`/events/${eventId}/timing?group=${group}`)
      : history.push(`/events/${eventId}/timing`);

  const { isLoading: isClassesLoading } = useCompClasses();

  const {
    isLoading,
    data,
    error,
    refetch: refetchEntries,
  } = useQuery(
    `event-${eventId}-timing-entries`,
    api.eventTimingEntries(eventId),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        return _(data)
          .map((e) => ({
            ...e,
            entry_id: `${e.group}${e.entry_num}`,
          }))
          .orderBy(["group", "entry_num"], ["asc", "asc"])
          .value();
      },
    }
  );
  const entries = data || [];

  const {
    isLoading: isEventLoading,
    data: event,
    error: eventError,
    refetch: refetchEvent,
  } = useQuery(`event-${eventId}`, api.event(eventId), {
    // refetchOnMount: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const groups = _.get(event, "groups", []);
  const selectedRunGroup = query.get("group") || groups[0];

  const { data: runLogData, refetch: refetchRunLogs } = useRunLogs({
    event,
    selectedRunGroup,
    entries: filteredEntries,
  });
  const runLogs = _.isEmpty(runLogData) ? [] : runLogData;

  const updateMutation = useUpdateEntryMutation(
    queryClient,
    eventId,
    selectedRunGroup
  );

  const updateEntryRun = (entry, data) => {
    // refetchRunLogs = { refetchRunLogs };
    updateMutation.mutate({ entry_id: entry.id, ...data });
  };

  useEffect(() => {
    refetchEvent();
  }, []);

  useEffect(() => {
    setFilteredEntries(
      selectedRunGroup
        ? entries.filter((e) => e.group === selectedRunGroup)
        : entries
    );
  }, [selectedRunGroup, entries]);

  const updateCacheForUpdatedEntry = async (updatedEntry) =>
    updateCacheForEntry(queryClient, eventId, updatedEntry);

  const updateCachedEntriesForNewEntry = async (newEntry) =>
    updateCacheForNewEntry(queryClient, eventId, newEntry);

  const handleSuccess = (data) => {
    refetchEvent();
    refetchEntries();

    toast({
      title: "Success",
      description: "Updated run count",
      // description: `Entry ${data.entry_id} - ${data.name} created`,
      status: "success",
      duration: 6000,
      isClosable: true,
    });
  };

  const updateRunGroupConfig = useMutation(
    (variables) =>
      api.updateRunGroupConfig(eventId, selectedRunGroup, variables),
    {
      onSuccess: handleSuccess,
      // onMutate: onMutate, // TODO: OFFLINE
    }
  );

  if (isLoading || isClassesLoading) return <LoadingScreen />;

  const handleRunCountChange = (count) => {
    updateRunGroupConfig.mutate(count);
  };

  const groupConfig = _.get(event, "config.groupConfigs", []).find(
    (c) => c.group === selectedRunGroup
  );
  let runCount;
  let runGroupRunCounts;
  if (selectedRunGroup) {
    runCount = groupConfig?.runCount || event?.config?.runCount || 0;
  } else {
    runGroupRunCounts = _.get(event, "config.groupConfigs", []).map(
      (c) => c.runCount || event.config.runCount
    );
    runCount = _.isEmpty(runGroupRunCounts)
      ? 0
      : Math.max(...runGroupRunCounts);
  }

  const runs = [...Array(parseInt(runCount))].map((v, idx) => idx + 1);

  return (
    <Box textAlign="left">
      <Head
        timingEnabled={timingEnabled}
        setTimingEnabled={setTimingEnabled}
        setDebugEnabled={setDebugEnabled}
        debugEnabled={debugEnabled}
        event={event}
        title={isEventLoading ? "Loading..." : event.name}
        eventId={eventId}
        entries={filteredEntries}
        selectedRunGroup={selectedRunGroup}
        setSelectedRunGroup={handleGroupChange}
        updateRunCount={handleRunCountChange}
        updateCacheForEntry={updateCachedEntriesForNewEntry}
        hideSignout={true}
        loggerConnection={loggerConnection}
      />
      <div style={styles.container} id="timing-container">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Logger
            updateLoggerConnection={setLoggerConnection}
            refetchEntries={refetchEntries}
            dequeueRunLog={dequeueRunLog}
            queuedRunLogs={queuedRunLogs}
            setQueuedRunLogs={setQueuedRunLogs}
            debugEnabled={debugEnabled}
            runLogs={runLogs}
            refetchRunLogs={refetchRunLogs}
            timingEnabled={timingEnabled}
            event={event}
            eventId={eventId}
            entries={filteredEntries}
            runs={runs}
            selectedRunGroup={selectedRunGroup}
          />
          <TimingTable
            queuedRunLogs={queuedRunLogs}
            debugEnabled={debugEnabled}
            timingEnabled={timingEnabled}
            event={event}
            eventId={eventId}
            entries={filteredEntries}
            runs={runs}
            selectedRunGroup={selectedRunGroup}
            updateEntryRun={updateEntryRun}
            updateCacheForEntry={updateCacheForUpdatedEntry}
          />
        </div>
        <Footer />
      </div>
    </Box>
  );
}

const styles = {
  container: {
    paddingTop: "50px",
    backgroundColor: "#EDF2F7",
  },
};

const useSearchParamsQuery = () => new URLSearchParams(useLocation().search);

// TimingSheet.whyDidYouRender = true;

export default TimingSheet;
