import { Heading, List, ListItem, Text } from "@chakra-ui/react";
import { Link, useRouteMatch } from "react-router-dom";

function SettingsSidebar() {
  const settingsMatch = useRouteMatch({
    path: "/settings",
    exact: true,
  });

  const orgMatch = useRouteMatch({
    path: "/organization",
    exact: true,
  });

  const indexFactorsMatch = useRouteMatch({
    path: "/index-factor-groups",
    // exact: false,
  });

  const classesMatch = useRouteMatch({
    path: "/classes",
    // exact: false,
  });

  const classModifiersMatch = useRouteMatch({
    path: "/class_modifiers",
    // exact: false,
  });

  const activeListStyle = {
    borderLeft: "2px solid #e53e3e",
    paddingLeft: 15,
    fontWeight: "bold",
  };
  const inactiveListStyle = {
    borderLeft: "2px solid #cbd5e0",
    paddingLeft: 15,
  };

  return (
    <>
      <Heading as="h5" size="sm" pt="5" color="gray.500">
        SETTINGS
      </Heading>
      <List spacing={0} pt="4">
        <ListItem
          style={
            orgMatch || settingsMatch ? activeListStyle : inactiveListStyle
          }
        >
          {orgMatch || settingsMatch ? (
            <Text as="p" style={{ paddingTop: 6, paddingBottom: 6 }}>
              Organization
            </Text>
          ) : (
            <Link to={"/organization"}>
              <Text as="p" style={{ paddingTop: 6, paddingBottom: 6 }}>
                Organization
              </Text>
            </Link>
          )}
        </ListItem>
        <ListItem
          style={indexFactorsMatch ? activeListStyle : inactiveListStyle}
        >
          {indexFactorsMatch ? (
            <Text as="p" style={{ paddingTop: 6, paddingBottom: 6 }}>
              Index Factors (PAX)
            </Text>
          ) : (
            <Link to={"/index-factor-groups"}>
              <Text as="p" style={{ paddingTop: 6, paddingBottom: 6 }}>
                Index Factors (PAX)
              </Text>
            </Link>
          )}
        </ListItem>
        <ListItem style={classesMatch ? activeListStyle : inactiveListStyle}>
          {classesMatch ? (
            <Text as="p" style={{ paddingTop: 6, paddingBottom: 6 }}>
              Classes
            </Text>
          ) : (
            <Link to={"/classes"}>
              <Text as="p" style={{ paddingTop: 6, paddingBottom: 6 }}>
                Classes
              </Text>
            </Link>
          )}
        </ListItem>
        <ListItem
          style={classModifiersMatch ? activeListStyle : inactiveListStyle}
        >
          {classModifiersMatch ? (
            <Text as="p" style={{ paddingTop: 6, paddingBottom: 6 }}>
              Class Modifiers
            </Text>
          ) : (
            <Link to={"/class_modifiers"}>
              <Text as="p" style={{ paddingTop: 6, paddingBottom: 6 }}>
                Class Modifiers
              </Text>
            </Link>
          )}
        </ListItem>
      </List>
    </>
  );
}

// SettingsSidebar.whyDidYouRender = true;

export default SettingsSidebar;
