import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./screens/login/Login";
import Classes from "./screens/classes/Classes";
import ClassModifiers from "./screens/class_modifiers/ClassModifiers";
import Events from "./screens/events/Events";
import EventDetail from "./screens/event-detail/EventDetail";
import SeriesDetail from "./screens/series-detail/SeriesDetail";
import TimingSheet from "./screens/timing-sheet/TimingSheet";
import IndexFactorGroup from "./screens/index-factor-group/IndexFactorGroup";
import IndexFactorGroups from "./screens/index-factor-groups/IndexFactorGroups";
import Organization from "./screens/organization/Organization";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/timing">
            <TimingSheet />
          </Route>
          <Route path="/classes">
            <Classes />
          </Route>
          <Route path="/class_modifiers">
            <ClassModifiers />
          </Route>
          <Route path="/events/:id/timing">
            <TimingSheet />
          </Route>
          <Route path="/series-events/:id/page/:page/items/:items">
            <SeriesDetail />
          </Route>
          <Route path="/events/page/:page/items/:items">
            <Events />
          </Route>
          <Route path="/events/:id">
            <EventDetail />
          </Route>
          <Route path="/events">
            <Events />
          </Route>
          <Route path="/settings">
            <Organization />
          </Route>
          <Route path="/organization">
            <Organization />
          </Route>
          <Route path="/index-factor-groups/:id">
            <IndexFactorGroup />
          </Route>
          <Route path="/index-factor-groups">
            <IndexFactorGroups />
          </Route>
          <Route path="/">
            <Events />
          </Route>
        </Switch>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
