import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Button,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { api } from "../../api";
import {
  Screen,
  TopControls,
  TopLeft,
  TopRight,
  Main,
  MainLeft,
  MainRight,
} from "../../components/template";
import Head from "../../components/Head";
import Footer from "../../components/Footer";
import SettingsSidebar from "../../components/SettingsSidebar";

function Organization() {
  const { isLoading, data, error } = useQuery(
    "organization",
    api.organization,
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const organization = data ? data : [];

  return (
    <Screen>
      <Head title="Settings" />
      <TopControls>
        <TopLeft></TopLeft>
        <TopRight>
          <Button style={{ visibility: "hidden" }} size="sm">
            Placeholder
          </Button>
        </TopRight>
      </TopControls>
      <Main>
        <MainLeft>
          <SettingsSidebar />
        </MainLeft>
        <MainRight heading="Organization">
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              disabled={true}
              size="lg"
              width={500}
              value={organization.name}
            />
            <FormHelperText>
              Contact{" "}
              <a href="mailto:support@solotiming.com?subject=Org name change">
                support@solotiming.com
              </a>{" "}
              to change
            </FormHelperText>
          </FormControl>
          <FormControl pt={12}>
            <FormLabel>Short Name</FormLabel>
            <Input
              disabled={true}
              size="lg"
              width={500}
              value={organization.short_name}
            />
            <FormHelperText>
              Contact{" "}
              <a href="mailto:support@solotiming.com?subject=Org short name change">
                support@solotiming.com
              </a>{" "}
              to change
            </FormHelperText>
          </FormControl>
          <FormControl pt={12}>
            <FormLabel>Contact Email</FormLabel>
            <Input
              disabled={true}
              type="email"
              size="lg"
              width={500}
              value={organization.contact_email}
            />
            <FormHelperText>
              Contact{" "}
              <a href="mailto:support@solotiming.com?subject=Contact email change">
                support@solotiming.com
              </a>{" "}
              to change
            </FormHelperText>
          </FormControl>
          <FormControl pt={12}>
            <FormLabel>Sync SCCA classes?</FormLabel>
            <Input
              disabled={true}
              type="sync_scca_classes"
              size="lg"
              width={500}
              value={organization.sync_scca_classes}
            />
            <FormHelperText>
              Contact{" "}
              <a href="mailto:support@solotiming.com?subject=Sync SCCA classes change">
                support@solotiming.com
              </a>{" "}
              to change
            </FormHelperText>
          </FormControl>
          <FormControl pt={12}>
            <FormLabel>MSR ID</FormLabel>
            <Input
              disabled={true}
              type="email"
              size="lg"
              width={500}
              value={organization.msr_id}
            />
            <FormHelperText>
              Contact{" "}
              <a href="mailto:support@solotiming.com?subject=Org MSR ID change">
                support@solotiming.com
              </a>{" "}
              to change
            </FormHelperText>
          </FormControl>
        </MainRight>
      </Main>
      <Footer />
    </Screen>
  );
}

// Organization.whyDidYouRender = true;

export default Organization;
