import React from "react";
import _ from "lodash";
import {
  Center,
  Flex,
  Heading,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { useState } from "react";

function EntryInfo({ event, entry, isSelectedEntry, onEdit, onSwap }) {
  const editable = _.get(event, "state") !== "archived";
  const [showControls, setShowControls] = useState(false);
  const handleEdit = () => onEdit(entry);
  const handleSwap = () => onSwap(entry);

  return (
    <Flex
      height="100px"
      // bg={isSelectedEntry ? "yellow.200" : "gray.200"}
      bg="white"
      direction="row"
      onMouseOver={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
      style={{ padding: 6 }}
    >
      {/* <Center width="50px"> */}
      {/* <Heading size="sm" color="gray.800" style={{ padding: 3 }}> */}
      <Text fontWeight="bold" style={{ paddingRight: 8 }}>
        {entry.entry_id}
      </Text>
      {/* </Center> */}
      <Flex direction="column" alignItems="flex-start">
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          {...styles.fixedWidthBox}
        >
          <Text fontWeight="bold">
            {/* {entry.entry_id} */}
            {entry.name}
          </Text>
          {editable && (
            <EditIcon
              color="gray.800"
              mr={2}
              cursor="pointer"
              visibility={showControls ? "visible" : "hidden"}
              onClick={handleEdit}
            />
          )}
          {/* <Menu>
            <MenuButton>
              <EditIcon
                color="gray.800"
                mr={2}
                cursor="pointer"
                visibility={showControls ? "visible" : "hidden"}
              />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleEdit}>Edit entry</MenuItem>
              <MenuItem onClick={handleSwap}>Swap with another entry</MenuItem>
            </MenuList>
          </Menu> */}
        </Flex>
        <Text {...styles.fixedWidthBox} style={{ fontSize: 14 }}>
          {entry.class_short} - {entry.class_modifier}
        </Text>
        <Text {...styles.fixedWidthBox} style={{ fontSize: 14 }}>
          {entry.vehicle_color}
          <br />
          {entry.vehicle}
        </Text>
      </Flex>
    </Flex>
  );
}

function areEqual(prevProps, nextProps) {
  return (
    prevProps.entry === nextProps.entry &&
    prevProps.isSelectedEntry === nextProps.isSelectedEntry
  );
}

const styles = {
  fixedWidthBox: {
    color: "gray.800",
    width: "200px",
    overflow: "hidden",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
  },
};

// EntryInfo.whyDidYouRender = true;

export default React.memo(EntryInfo, areEqual);
