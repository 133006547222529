import { useRef } from "react";
import { useQuery } from "react-query";
import {
  Button,
  FormControl,
  FormLabel,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  Heading,
  Progress,
} from "@chakra-ui/react";
import { api } from "../../api";

export default function EventSelect({ close, setMsrEvent, msrEvent }) {
  const selectRef = useRef();

  const { isLoading, data, error } = useQuery("msr-events", api.msrEvents, {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const handleNext = () => {
    const event = data.find((e) => e.id === selectRef.current.value);
    setMsrEvent(event);
  };

  const msrEvents = data ? data : [];

  return (
    <ModalContent>
      <ModalHeader>Import MSR event</ModalHeader>
      {!isLoading && (
        <>
          <ModalBody>
            <FormControl>
              <FormLabel>Select event</FormLabel>
              <Select
                ref={selectRef}
                placeholder="Select an event"
                defaultValue={msrEvent && msrEvent.id}
              >
                {msrEvents
                  .sort((a, b) => b.start.localeCompare(a.start))
                  .map((event) => (
                    <option key={`msr-event-${event.id}`} value={event.id}>
                      {event.name} - {event.start}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={close} ml={3}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleNext} ml={3}>
              Next
            </Button>
          </ModalFooter>
        </>
      )}
      {isLoading && (
        <ModalBody>
          <Progress size="xs" isIndeterminate my={8} />
        </ModalBody>
      )}
      {error && (
        <ModalBody>
          <Heading size="sm" pb={4} color="red.500">
            An error occurred fetching events from MSR. Try again in a minute.
          </Heading>
        </ModalBody>
      )}
    </ModalContent>
  );
}
