import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  Button,
  FormControl,
  FormLabel,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Heading,
  Progress,
} from "@chakra-ui/react";
import { api } from "../../api";

export default function SeriesSelect({ close, onFinish }) {
  const [checkedState, setCheckedState] = useState([]);

  useEffect(() => {
    console.log("setting series select shown");
  }, []);

  const { isLoading, data, error } = useQuery(
    "active-series",
    api.eventSeries(),
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCheckedState(new Array(data.length).fill(false));
      },
    }
  );

  const handleNext = () => {
    const selectedSeries = data
      .filter((series, index) => checkedState[index])
      .map((series) => series.id);
    onFinish(selectedSeries);
  };

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  };

  const series = data ? data : [];

  return (
    <ModalContent>
      <ModalHeader>Import MSR event</ModalHeader>

      {!isLoading && (
        <>
          <ModalBody>
            <FormControl>
              <FormLabel>Is this event part of a series?</FormLabel>
              <div role="group" aria-labelledby="checkbox-group">
                {series.map((s, index) => (
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name={s.name}
                        value={s.id}
                        checked={checkedState[index]}
                        onChange={() => handleOnChange(index)}
                      ></input>{" "}
                      {s.name}
                    </label>
                  </div>
                ))}
              </div>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={close} ml={3}>
              Close
            </Button>
            <Button colorScheme="blue" onClick={handleNext} ml={3}>
              Next
            </Button>
          </ModalFooter>
        </>
      )}
      {isLoading && (
        <ModalBody>
          {/* <Heading size="sm" pb={4}>
            Importing segments from MSR...
          </Heading> */}
          <Progress size="xs" isIndeterminate my={8} />
        </ModalBody>
      )}
      {error && (
        <ModalBody>
          <Heading size="sm" pb={4} color="red.500">
            Unable to import MSR event segments. Please try again in a minute.
          </Heading>
        </ModalBody>
      )}
    </ModalContent>
  );
}
