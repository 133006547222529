import { useToast, Button } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { api } from "../api";

export default function SignOut() {
  const history = useHistory();
  const toast = useToast();

  const handleSuccess = (data) => {
    history.push("/login");
    toast({
      title: "Success",
      description: "Logged out",
      status: "success",
      duration: 6000,
      isClosable: true,
    });
  };

  const logoutMutation = useMutation((variables) => api.logout(variables), {
    onSuccess: handleSuccess,
    // onMutate: onMutate, // TODO: OFFLINE
  });

  const signOut = () => {
    console.log("signing out");
    logoutMutation.mutate();
  };

  return (
    <Button color="white" variant="link" onClick={signOut}>
      Sign Out
    </Button>
  );
}
