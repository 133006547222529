import { useRef } from "react";
import _ from "lodash";
import {
  Button,
  FormErrorMessage,
  Heading,
  Input,
  Modal,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Progress,
  FormControl,
  FormLabel,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useMutation, useQuery } from "react-query";
import { api } from "../../api";
import { validatePresent } from "../../validators";

export default function EventDetailForm({
  initialEvent = {},
  isOpen,
  onClose,
  onMutate,
}) {
  console.log("initialEvent.event_series_id", initialEvent.event_series_id);
  const selectRef = useRef();

  const { isLoadingSeries, data, error } = useQuery(
    "active-series",
    api.eventSeries(),
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleSuccess = (data) => {
    toast({
      title: "Success",
      description: `Event ${data.name} saved`,
      status: "success",
      duration: 6000,
      isClosable: true,
    });
    onClose();
    onMutate(data);
  };

  const toast = useToast();

  const updateMutation = useMutation(
    (variables) => api.updateEvent(initialEvent.id, variables),
    {
      onSuccess: handleSuccess,
      onError: (err) => {
        let errorDescription;
        if (err.response.headers["content-type"].includes("application/json")) {
          errorDescription = _(err.response.data || {})
            .toPairs()
            .flatMap()
            .join(" ");
        }

        toast({
          title: "Error",
          description: errorDescription || "Error saving event",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      },
    }
  );

  const series = data ? data : [];

  const isFormLoading = updateMutation.isLoading;

  const handleSubmit = (values) => {
    updateMutation.mutate(values);
  };

  if (isOpen && isLoadingSeries) {
    return <></>;
  }

  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <Formik
          initialValues={_.pick(
            initialEvent,
            "name",
            "start_date",
            "venue",
            "event_series_id"
          )}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <>
              <ModalHeader>Edit Event</ModalHeader>
              {isFormLoading && (
                <ModalBody>
                  <Heading size="sm">
                    <Text>Saving</Text>
                    <Progress size="xs" isIndeterminate my={8} />
                  </Heading>
                </ModalBody>
              )}

              {!isFormLoading && (
                <ModalBody>
                  <Form>
                    <Field name="name" validate={validatePresent("name")}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                          mb={6}
                        >
                          <FormLabel htmlFor="name">Name</FormLabel>
                          <Input
                            {...field}
                            id="name"
                            placeholder="name of the series"
                            autoComplete="off"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="start_date"
                      validate={validatePresent("start_date")}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.start_date && form.touched.start_date
                          }
                          mb={6}
                        >
                          <FormLabel htmlFor="start_date">Start Date</FormLabel>
                          <Input
                            {...field}
                            id="start_date"
                            placeholder="start_date of the series"
                            autoComplete="off"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.start_date}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="venue" validate={validatePresent("venue")}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.venue && form.touched.venue}
                          mb={6}
                        >
                          <FormLabel htmlFor="venue">venue</FormLabel>
                          <Input
                            {...field}
                            id="venue"
                            placeholder="venue of the series"
                            autoComplete="off"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.venue}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="event_series_id">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.event_series_id &&
                            form.touched.event_series_id
                          }
                          mb={6}
                        >
                          <FormLabel htmlFor="event_series_id">
                            Series
                          </FormLabel>
                          {!isLoadingSeries && (
                            <Select
                              {...field}
                              id="event_series_id"
                              placeholder="Select a series"
                            >
                              {series.map((s) => (
                                <option key={`series-${s.id}`} value={s.id}>
                                  {s.name}
                                </option>
                              ))}
                            </Select>
                          )}
                          <FormErrorMessage>
                            {form.errors.event_series_id}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Form>
                </ModalBody>
              )}

              <ModalFooter>
                <Button onClick={onClose} ml={3}>
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  ml={3}
                  type="submit"
                  onClick={props.handleSubmit}
                >
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}
