import { Flex } from "@chakra-ui/react";

export default function TopControls({ children }) {
  return (
    <Flex
      textAlign="right"
      mt="50px"
      p={6}
      pb={0}
      mb={0}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {children}
    </Flex>
  );
}
