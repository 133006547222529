import { useQuery } from "react-query";
import {
  Box,
  Button,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { Link, useRouteMatch, useParams } from "react-router-dom";
import { api } from "../../api";
import LoadingScreen from "../../components/LoadingScreen";
import SeriesFormButton from "../series-detail/SeriesFormButton";

function EventsSidebar() {
  let params = useParams();
  let { id } = params;
  id ||= "";

  const {
    isLoading,
    data,
    error,
    refetch: refetchEventSeries,
  } = useQuery("event-series", api.eventSeries(), {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const eventSeries = data ? data : [];

  console.log("eventSeries", eventSeries);

  const eventsMatch = useRouteMatch({
    path: "/events",
    exact: false,
  });

  const seriesMatch = useRouteMatch({
    path: "/series-events",
    exact: false,
  });

  const activeListStyle = {
    borderLeft: "2px solid #e53e3e",
    paddingLeft: 15,
    fontWeight: "bold",
  };
  const inactiveListStyle = {
    borderLeft: "2px solid #cbd5e0",
    paddingLeft: 15,
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading as="h5" size="sm" color="gray.500" pt="5" pb={4}>
          SERIES
        </Heading>
        <SeriesFormButton label="+ New Series" onMutate={refetchEventSeries} />
      </Flex>
      <List spacing={0}>
        {/* <ListItem style={eventsMatch ? activeListStyle : inactiveListStyle}>
          {eventsMatch ? (
            <Text as="p" style={{ paddingTop: 6, paddingBottom: 6 }}>
              All Events
            </Text>
          ) : (
            <Link to={"/events"}>
              <Text as="p" style={{ paddingTop: 6, paddingBottom: 6 }}>
                All Events
              </Text>
            </Link>
          )}
        </ListItem> */}
        {eventSeries.map((series) => (
          <ListItem
            style={
              seriesMatch && series.id === id
                ? activeListStyle
                : inactiveListStyle
            }
          >
            {seriesMatch && series.id === id ? (
              <Text as="p" style={{ paddingTop: 6, paddingBottom: 6 }}>
                {series.name}
              </Text>
            ) : (
              <Link to={`/series-events/${series.id}/page/1/items/10`}>
                <Text as="p" style={{ paddingTop: 6, paddingBottom: 6 }}>
                  {series.name}
                </Text>
              </Link>
            )}
          </ListItem>
        ))}
      </List>
    </>
  );
}

// EventsSidebar.whyDidYouRender = true;

export default EventsSidebar;
