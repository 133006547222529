import { useState } from "react";
import _ from "lodash";
import { Button, Progress } from "@chakra-ui/react";
import { useClassModifiers } from "../../hooks";
import Head from "../../components/Head";
import Footer from "../../components/Footer";
import LoadingScreen from "../../components/LoadingScreen";
import ClassModifiersTable from "./ClassModifiersTable";
import SettingsSidebar from "../../components/SettingsSidebar";
import {
  Screen,
  TopControls,
  TopLeft,
  TopRight,
  Main,
  MainLeft,
  MainRight,
} from "../../components/template";
import AddClassModifier from "./AddClassModifier";

function ClassModifiers() {
  const { isLoading, data, refetch } = useClassModifiers();

  const [selectedClass, setSelectedClass] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const handleClick = () => {
    setSelectedClass({});
    setModalOpen(true);
  };
  const handleClose = () => setModalOpen(false);
  const handleRowClick = (clickedClass) => {
    console.log("row clicked", clickedClass);
    setSelectedClass(clickedClass);
    setModalOpen(true);
  };

  if (isLoading) return <LoadingScreen />;

  const classes = _(data ? data : [])
    .orderBy(["state", "short_name"], ["asc", "asc"])
    .value();

  return (
    <Screen>
      <Head title="Settings" />
      <TopControls>
        <TopLeft></TopLeft>
        <TopRight>
          <Button onClick={handleClick} colorScheme="green" size="sm">
            + New Class Modifier
          </Button>
        </TopRight>
      </TopControls>
      <Main>
        <MainLeft>
          <SettingsSidebar />
        </MainLeft>
        <MainRight heading="Class Modifiers">
          {isLoading ? (
            <Progress size="xs" isIndeterminate my={8} />
          ) : (
            <ClassModifiersTable
              classes={classes}
              onClick={handleRowClick}
              onMutate={refetch}
            />
          )}
        </MainRight>
      </Main>
      <Footer />
      {isModalOpen && (
        <AddClassModifier
          isOpen={isModalOpen}
          onClose={handleClose}
          onMutate={refetch}
          initialClassModifier={selectedClass}
        />
      )}
    </Screen>
  );
}

// Classes.whyDidYouRender = true;

export default ClassModifiers;
