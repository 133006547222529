import _ from "lodash";
import {
  Badge,
  Button,
  IconButton,
  List,
  ListItem,
  ListIcon,
  Text,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Switch,
  SimpleGrid,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AddIcon,
  CalendarIcon,
  ChevronDownIcon,
  HamburgerIcon,
  SettingsIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import { useRef, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import AddEntry from "./AddEntry";
import SignOut from "./SignOut";

const runOptions = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];

function Head({
  timingEnabled,
  setTimingEnabled,
  debugEnabled,
  setDebugEnabled,
  event,
  title,
  eventId,
  entries,
  selectedRunGroup,
  setSelectedRunGroup,
  updateRunCount,
  updateCacheForEntry,
  hideSignout = false,
  loggerConnection = "",
}) {
  const groups = _.get(event, "groups", []);

  // const classesMatch = useRouteMatch({
  //   path: "/classes",
  //   exact: true,
  // });

  const eventsMatch = useRouteMatch({
    path: "/events",
    exact: false,
  });

  const settingsMatch = useRouteMatch({
    path: "/settings",
    exact: true,
  });

  const timingSheetMatch = useRouteMatch({
    path: "/events/:id/timing",
    exact: true,
  });

  const runCountRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isChangeRunCountModalOpen, setIsChangeRunCountModalOpen] =
    useState(false);

  const [isAddEntryModalOpen, setIsAddEntryModalOpen] = useState(false);

  const handleChangeRunCountUpdate = (ev) => {
    updateRunCount(runCountRef.current.value);
    setIsChangeRunCountModalOpen(false);
  };

  const handleChangeRunCountModalClose = () =>
    setIsChangeRunCountModalOpen(false);

  const handleRunGroupChange = (runGroup) => (ev) => {
    setSelectedRunGroup(runGroup);
  };

  const handleChangeRunCountClick = () => {
    setIsChangeRunCountModalOpen(true);
  };

  const handleAddEntryClick = () => {
    setIsAddEntryModalOpen(true);
  };

  const handleAddEntryClose = () => {
    setIsAddEntryModalOpen(false);
  };

  const toggleTiming = () => {
    setTimingEnabled(!timingEnabled);
  };

  const toggleDebug = () => {
    setDebugEnabled(!debugEnabled);
  };

  return (
    <>
      <Flex
        pos="fixed"
        w="100%"
        top="0"
        height="50px"
        bg="gray.700"
        zIndex="100"
        justifyContent="space-between"
      >
        <Flex w={200} justifyContent="flex-start" alignItems="center" ml={4}>
          <IconButton
            aria-label="Navigation"
            bg="transparent"
            variant="unstyled"
            color="white"
            // as={Button}
            icon={<HamburgerIcon w={6} h={6} color="gray.300" />}
            onClick={onOpen}
          ></IconButton>
          <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            size="xs"
            bg="gray.600"
          >
            <DrawerOverlay>
              <DrawerContent bg="gray.800">
                {/* <DrawerCloseButton /> */}
                <DrawerHeader
                  borderBottomWidth="1px"
                  borderBottomColor="gray.600"
                  color="white"
                >
                  Solo Timing
                </DrawerHeader>
                <DrawerBody>
                  <List spacing={6} mt={4}>
                    <ListItem alignItems="center" cursor="pointer">
                      <Flex alignItems="center">
                        <ListIcon
                          as={CalendarIcon}
                          color={eventsMatch ? "white" : "gray.600"}
                          w={5}
                          h={5}
                        />
                        <Link
                          to="/events"
                          style={{ width: "100%", height: "100%" }}
                        >
                          <Text
                            as="span"
                            fontSize="xl"
                            color={eventsMatch ? "green.300" : "gray.300"}
                          >
                            Events
                          </Text>
                        </Link>
                      </Flex>
                    </ListItem>
                    {/* <ListItem alignItems="center" cursor="pointer">
                      <Flex alignItems="center">
                        <ListIcon
                          as={TimeIcon}
                          color={classesMatch ? "white" : "gray.600"}
                          w={5}
                          h={5}
                        />
                        <Link
                          to="/classes"
                          style={{ width: "100%", height: "100%" }}
                        >
                          <Text
                            as="span"
                            fontSize="xl"
                            color={classesMatch ? "green.300" : "gray.300"}
                          >
                            Classes
                          </Text>
                        </Link>
                      </Flex>
                    </ListItem> */}
                    <ListItem alignItems="center" cursor="pointer">
                      <Flex alignItems="center">
                        <ListIcon
                          as={SettingsIcon}
                          color={settingsMatch ? "white" : "gray.600"}
                          w={5}
                          h={5}
                        />
                        <Link
                          to="/settings"
                          style={{ width: "100%", height: "100%" }}
                        >
                          <Text
                            as="span"
                            fontSize="xl"
                            color={settingsMatch ? "green.300" : "gray.300"}
                          >
                            Settings
                          </Text>
                        </Link>
                      </Flex>
                    </ListItem>
                  </List>
                </DrawerBody>
                <DrawerFooter></DrawerFooter>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
          {groups.length > 0 && (
            <Menu>
              {selectedRunGroup && (
                <>
                  <MenuButton
                    ml={6}
                    aria-label="Settings"
                    bg="transparent"
                    variant="unstyled"
                    color="white"
                    as={Button}
                    rightIcon={<ChevronDownIcon w={6} h={6} color="gray.300" />}
                  >
                    {`Group ${selectedRunGroup}`}
                  </MenuButton>
                  <MenuList>
                    {groups.map((runGroup) => (
                      <MenuItem
                        onClick={handleRunGroupChange(runGroup)}
                        key={`run-group-option-${runGroup}`}
                      >
                        Group {runGroup}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          )}
        </Flex>
        <Center>
          <Heading color="gray.200" as="h2" size="md" isTruncated>
            {title}
          </Heading>
        </Center>
        <Flex w={200} justifyContent="flex-end" alignItems="center" pr={3}>
          {!hideSignout && <SignOut />}
          {timingSheetMatch && _.get(event, "state") !== "archived" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: 20,
              }}
            >
              {loggerConnection === "Open" && (
                <Badge variant="solid" colorScheme="green" mr={12}>
                  Logger&nbsp;Connection&nbsp;{loggerConnection}
                </Badge>
              )}
              {loggerConnection === "Closed" && (
                <Badge variant="solid" colorScheme="red" mr={12}>
                  Logger&nbsp;Connection&nbsp;{loggerConnection}
                </Badge>
              )}
              {loggerConnection !== "Closed" && loggerConnection !== "Open" && (
                <Badge variant="solid" colorScheme="gray" mr={12}>
                  Logger&nbsp;Connection&nbsp;{loggerConnection}
                </Badge>
              )}
              <FormLabel
                htmlFor="isTimingEnabled"
                color="white"
                m={0}
                mr={2}
                fontWeight="bold"
              >
                TIMING
              </FormLabel>
              <Switch
                id="isTimingEnabled"
                size="lg"
                colorScheme="green"
                isChecked={timingEnabled}
                onChange={toggleTiming}
              />
              <FormLabel
                htmlFor="isDebugEnabled"
                color="white"
                m={0}
                mr={2}
                fontWeight="bold"
                pl={10}
              >
                DEBUG
              </FormLabel>
              <Switch
                id="isDebugEnabled"
                size="lg"
                colorScheme="green"
                isChecked={debugEnabled}
                onChange={toggleDebug}
              />
            </div>
          )}
          {timingSheetMatch && _.get(event, "state") !== "archived" && (
            <Menu>
              <MenuButton
                aria-label="Settings"
                bg="transparent"
                variant="unstyled"
                color="white"
                as={Button}
                rightIcon={<SettingsIcon w={6} h={6} color="gray.300" />}
              ></MenuButton>
              <MenuList>
                {false && (
                  <MenuItem onClick={handleAddEntryClick}>
                    <AddIcon color="gray.300" mr={3} />
                    Add entry
                  </MenuItem>
                )}
                <MenuItem onClick={handleChangeRunCountClick}>
                  <SettingsIcon color="gray.300" mr={3} />
                  Change run count
                  <Modal
                    onClose={handleChangeRunCountModalClose}
                    size="md"
                    isOpen={isChangeRunCountModalOpen}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Change run count</ModalHeader>
                      <ModalBody>
                        <FormControl>
                          <FormLabel>Select number of runs</FormLabel>
                          <Select ref={runCountRef}>
                            {runOptions.map((runOption) => (
                              <option
                                key={`run-option-${runOption}`}
                                value={runOption}
                              >
                                {runOption}
                              </option>
                            ))}
                          </Select>
                        </FormControl>

                        <Alert status="warning" mt={4}>
                          <AlertIcon />
                          Decreasing the number of runs can cause existing run
                          data to be lost!
                        </Alert>
                      </ModalBody>
                      <ModalFooter>
                        <Button onClick={handleChangeRunCountModalClose} ml={3}>
                          Cancel
                        </Button>
                        <Button
                          colorScheme="blue"
                          onClick={handleChangeRunCountUpdate}
                          ml={3}
                        >
                          Update
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>
      <AddEntry
        eventId={eventId}
        entry={{ class_long: "B Street" }}
        entries={entries}
        isOpen={isAddEntryModalOpen}
        onClose={handleAddEntryClose}
        onMutate={updateCacheForEntry}
      />
    </>
  );
}

// Head.whyDidYouRender = true;

export default Head;
