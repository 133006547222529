import {
  Text,
  ModalBody,
  Heading,
  ModalContent,
  ModalHeader,
  Progress,
} from "@chakra-ui/react";

export default function Loading() {
  return (
    <ModalContent>
      <ModalHeader>Import MSR event</ModalHeader>
      <ModalBody>
        <Heading size="sm">
          <Text>Importing</Text>
          <Progress size="xs" isIndeterminate my={8} />
        </Heading>
      </ModalBody>
    </ModalContent>
  );
}
