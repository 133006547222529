import { useState } from "react";
import _ from "lodash";
import {
  Button,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { TimeIcon } from "@chakra-ui/icons";
import { RiTrophyLine as AiOutlineTrophy, RiPrinterLine } from "react-icons/ri";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { api } from "../../api";
import { Progress } from "@chakra-ui/react";
import EntryForm from "./EntryForm";
import EntriesTable from "./EntriesTable";
import Head from "../../components/Head";
import Footer from "../../components/Footer";
import LoadingScreen from "../../components/LoadingScreen";
import EventDetailSidebar from "./EventDetailSidebar";
import { useLoginOnUnauthorized } from "../../hooks";
import {
  Screen,
  TopControls,
  TopLeft,
  TopRight,
  Main,
  MainLeft,
  MainRight,
} from "../../components/template";
import EntryFormButton from "./EntryFormButton";

const API_BASE =
  process.env.REACT_APP_API_BASE_URL || "http://api.solotiming.test:3001";

function EventDetail() {
  const handleUnauthorizedError = useLoginOnUnauthorized();

  const history = useHistory();
  const { id: eventId } = useParams();

  const [isEditEntryModalOpen, setIsEditEntryModalOpen] = useState(false);
  const [editingEntry, setEditingEntry] = useState({});

  const { isLoading, data, error } = useQuery(
    `event-${eventId}`,
    api.event(eventId),
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onError: handleUnauthorizedError,
    }
  );

  const {
    isLoading: isEntriesLoading,
    data: entriesData,
    error: entriesError,
    refetch: refetchEntries,
  } = useQuery(`event-${eventId}-entries`, api.eventEntries(eventId), {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: handleUnauthorizedError,
  });

  const entries = _(entriesData ? entriesData : [])
    .orderBy(["group", "entry_num"], ["asc", "asc"])
    .value();

  if (isLoading) return <LoadingScreen />;

  const event = data ? data : {};

  const handleEntryEditClose = () => {
    setIsEditEntryModalOpen(false);
    setEditingEntry({});
  };

  const handleEntryEdit = (entry) => {
    setIsEditEntryModalOpen(true);
    setEditingEntry(entry);
  };

  return (
    <Screen>
      <Head event={event} title={event ? event.name : "Event Detail"} />
      <TopControls>
        <TopLeft>
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink href="/events">Events</BreadcrumbLink>
            </BreadcrumbItem>
            {event.series && (
              <BreadcrumbItem>
                <BreadcrumbLink
                  href={`/series-events/${event.series.id}/page/1/items/10`}
                >
                  {event.series.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            )}
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href="#">
                <strong>{event.name}</strong>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </TopLeft>
        <TopRight>
          <a
            href={`${API_BASE}/events/${eventId}/timing_entries.xlsx`}
            download="Printable Timing Sheet"
            target="_blank"
          >
            <Button leftIcon={<RiPrinterLine />} mr={2} size="sm">
              Print Timing Sheet
            </Button>
          </a>
          <Button
            leftIcon={<TimeIcon />}
            mr={2}
            onClick={() => history.push(`/events/${eventId}/timing`)}
            size="sm"
          >
            Electronic Timing Sheet
          </Button>
          <Button
            size="sm"
            leftIcon={<Icon as={AiOutlineTrophy} />}
            onClick={() =>
              (window.location = `${API_BASE}${
                event.state === "archived"
                  ? `/results/${eventId}`
                  : "/results/live"
              }`)
            }
          >
            {event.state === "archived" ? "Results" : "Live Results"}
          </Button>
        </TopRight>
      </TopControls>
      <Main>
        <MainLeft>
          <EventDetailSidebar />
        </MainLeft>
        <MainRight>
          {isLoading ? (
            <Progress size="xs" isIndeterminate my={8} />
          ) : (
            <>
              <div style={{ textAlign: "right" }}>
                <EntryFormButton
                  label="+ New Entry"
                  event={event}
                  onMutate={refetchEntries}
                />
              </div>
              <EntriesTable
                entries={entries}
                onEdit={handleEntryEdit}
                refetchEntries={refetchEntries}
              />
            </>
          )}
        </MainRight>
      </Main>
      <EntryForm
        eventId={event.id}
        entry={editingEntry}
        entries={entries}
        isOpen={isEditEntryModalOpen}
        onClose={handleEntryEditClose}
        onSuccess={refetchEntries}
      />
      <Footer />
    </Screen>
  );
}

// EventDetail.whyDidYouRender = true;

export default EventDetail;
