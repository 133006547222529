import { sortBy } from "lodash";
import { useRef } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
} from "@chakra-ui/react";

export default function AssignEntryModal({
  entries,
  isOpen,
  onClose,
  assignEntryToRunLog,
}) {
  const entryIdRef = useRef();

  const handleSubmit = (ev) => {
    assignEntryToRunLog(entries.find((e) => e.id === entryIdRef.current.value));
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Assign Entry</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select ref={entryIdRef} placeholder="Select option">
            {sortBy(entries, ["group_id"], ["asc"]).map((entry) => (
              <option value={entry.id} key={entry.id}>
                {entry.entry_id} - {entry.name} - {entry.class_short} -{" "}
                {entry.class_modifier} - {entry.vehicle_color} - {entry.vehicle}
              </option>
            ))}
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Assign Selected Entry
          </Button>
          <Button variant="ghost">Assign New Entry</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
