import { isEmpty, reverse } from "lodash";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDisclosure } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { useTimingMonitor } from "../../../hooks";
import QueuedRunLog from "./QueuedRunLog";
import FinishedRunLog from "./FinishedRunLog";
import UnassignedRunLog from "./UnassignedRunLog";
import { firstBlankRun } from "../../../utils";
import {
  useCreateRunLogMutation,
  useUpdateRunLogMutation,
  nextEntry,
  updateCacheForEntry,
} from "./utils";
import AssignEntryModal from "./AssignEntryModal";
import QueueOtherModal from "./QueueOtherModal";

export default function Logger({
  refetchEntries,
  dequeueRunLog,
  queuedRunLogs,
  setQueuedRunLogs,
  debugEnabled,
  runLogs,
  refetchRunLogs,
  entries,
  event,
  selectedRunGroup,
  timingEnabled,
  updateLoggerConnection,
}) {
  // TODO: Figure out how/if to handle group change behavior
  // useEffect(() => {
  //   console.log("REFETCHING FOR GROUP", selectedRunGroup);
  //   // setQueuedRunLogs([]);
  //   // refetchRunLogs();
  // }, [selectedRunGroup]);

  const queryClient = useQueryClient();

  const [assignedEntryLog, setAssignedEntryLog] = useState(null);

  const createLogMutation = useCreateRunLogMutation(
    queryClient,
    event,
    selectedRunGroup
  );
  const updateLogMutation = useUpdateRunLogMutation(
    queryClient,
    event,
    selectedRunGroup
  );

  const { loggedTime, logId, connectionStatus } = useTimingMonitor();

  useEffect(() => {
    if (updateLoggerConnection) updateLoggerConnection(connectionStatus);
  }, [connectionStatus]);

  useEffect(() => {
    if (!timingEnabled) return;

    if (queuedRunLogs.length > 0) {
      const entry = entries.find((e) => e.id === queuedRunLogs[0].entry_id);
      createFinishedLogFromQueuedLog(entry, queuedRunLogs[0], loggedTime);
    } else {
      createFinishedLogFromUnassignedLog(queuedRunLogs[0], loggedTime);
    }
  }, [logId]);

  const { nextRunEntry, nextRun } = nextEntry(entries, queuedRunLogs, runLogs);

  const {
    isOpen: isAssignEntryModalOpen,
    onOpen: onAssignEntryModalOpen,
    onClose: onAssignEntryModalClose,
  } = useDisclosure();

  const {
    isOpen: isQueueOtherModalOpen,
    onOpen: onQueueOtherModalOpen,
    onClose: onQueueOtherModalClose,
  } = useDisclosure();

  const pushQueuedRunLog = (log) => {
    setQueuedRunLogs((logs) => [...logs, log]);
  };

  const assignEntryToUnassignedLog = (entry) => {
    const run = firstBlankRun(entry.runs);
    assignedEntryLog["entry"] = entry;
    assignedEntryLog["entry_id"] = entry.id;
    assignedEntryLog["run_id"] = run.id;

    const runLog = {
      uuid: assignedEntryLog.uuid,
      event_id: event.id,
      group: selectedRunGroup,
      entry_id: entry.id,
      run_id: run.id,
      data: {
        id: run.id,
        ...assignedEntryLog.data,
      },
    };

    updateLogMutation.mutate({
      run_log_id: assignedEntryLog.id,
      run_log: runLog,
    });

    const updatedRuns = entry.runs.map((r) => {
      if (r.id == run.id) {
        return runLog.data;
      } else {
        return r;
      }
    });

    updateCacheForEntry(queryClient, event, entry, { runs: updatedRuns });
  };

  const updateRunLog = (runLog, updates) => {
    const updatedData = { ...runLog.data, ...updates, id: runLog?.run_id };
    const updatedRunLog = { ...runLog, data: updatedData };

    updateLogMutation.mutate({
      run_log_id: runLog.id,
      run_log: updatedRunLog,
    });

    // TODO: Replace with client-only update!!!
    // refetchRunLogs();
    // TODO: Replace with client-only update!!!

    if (runLog.entry) {
      const updatedRuns = runLog.entry.runs.map((r) => {
        if (r.id == runLog.run_id) {
          return updatedRunLog.data;
        } else {
          return r;
        }
      });
      updateCacheForEntry(queryClient, event, runLog.entry, {
        runs: updatedRuns,
      });
    }
  };

  const updateQueuedRunLog = (runLog, updates) => {
    const updatedData = { ...runLog.data, ...updates, id: runLog?.run_id };
    const updatedRunLog = { ...runLog, data: updatedData };

    const indexOfLog = queuedRunLogs.indexOf(runLog);
    const updatedQueuedLogs = [...queuedRunLogs];
    updatedQueuedLogs[indexOfLog] = updatedRunLog;
    setQueuedRunLogs([...updatedQueuedLogs]);

    if (runLog.entry) {
      const updatedRuns = runLog.entry.runs.map((r) => {
        if (r.id == runLog.run_id) {
          return updatedRunLog.data;
        } else {
          return r;
        }
      });
      updateCacheForEntry(queryClient, event, runLog.entry, {
        runs: updatedRuns,
      });
    }
  };

  const createQueueLogFromNextEntry = (entry, run) => {
    pushQueuedRunLog({
      uuid: uuidv4(),
      entry: entry,
      entry_id: entry.id,
      run_id: run.id,
      data: {
        scratchTime: null,
        dnf: false,
        penaltyCount: 0,
        id: run.id,
      },
    });
  };

  const createFinishedLogFromQueuedLog = (entry, queuedLog, time) => {
    const run = entry.runs.find((r) => r.id === queuedLog.run_id);
    const runLog = {
      uuid: uuidv4(),
      event_id: event.id,
      group: selectedRunGroup,
      entry_id: entry.id,
      entry: entry,
      run_id: run.id,
      data: {
        scratchTime: time,
        dnf: queuedLog.data.dnf,
        penaltyCount: queuedLog.data.penaltyCount,
        id: run.id,
      },
    };

    createLogMutation.mutate(runLog);

    let updatedQueue = [...queuedRunLogs];
    updatedQueue.shift();
    setQueuedRunLogs([...updatedQueue]);

    const updatedRuns = entry.runs.map((r) =>
      r.id == run.id ? runLog.data : r
    );

    updateCacheForEntry(queryClient, event, entry, {
      runs: updatedRuns,
    });
  };

  const createFinishedLogFromUnassignedLog = (unassignedLog, time) => {
    createLogMutation.mutate({
      uuid: uuidv4(),
      event_id: event.id,
      group: selectedRunGroup,
      entry_id: null,
      run_id: uuidv4(),
      entry: null,
      data: {
        scratchTime: time,
        dnf: false,
        penaltyCount: 0,
      },
    });
  };

  const handleAssignEntryClick = (log) => {
    setAssignedEntryLog(log);
    onAssignEntryModalOpen();
  };

  const handleQueueOtherClick = () => {
    // setAssignedEntryLog(log);
    onQueueOtherModalOpen();
  };

  const sortedQueuedLogs = queuedRunLogs.slice().reverse();
  const sortedRunLogs = runLogs;

  const entryRunIdx = (log) => {
    if (isEmpty(log.entry)) {
      return -1;
    } else {
      return log.entry.runs.findIndex((r) => r.id === log.run_id);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.stickyHeader}>
        <div style={styles.queueButtons}>
          {timingEnabled ? (
            <>
              <QueueNextButton
                entry={nextRunEntry}
                run={nextRun}
                onClick={createQueueLogFromNextEntry}
              />
              <QueueOtherButton onClick={handleQueueOtherClick} />
            </>
          ) : (
            <TimerDisabledWarning />
          )}
        </div>
      </div>
      <div style={styles.body}>
        {sortedQueuedLogs.length > 0 && (
          <div style={styles.queuedLogList}>
            <div style={styles.queuedSpacer}>QUEUED</div>
            {sortedQueuedLogs.map((log, idx) => (
              <>
                <QueuedRunLog
                  event={event}
                  key={log.uuid}
                  log={log}
                  queueIdx={sortedQueuedLogs.length - idx - 1}
                  runIdx={entryRunIdx(log)}
                  updateRunLog={updateQueuedRunLog}
                  timingEnabled={timingEnabled}
                  debugEnabled={debugEnabled}
                  dequeueRunLog={dequeueRunLog}
                />
                <Spacer queued={true} />
              </>
            ))}
          </div>
        )}
        {runLogs.length > 0 && (
          <div style={styles.finishedLogList}>
            {queuedRunLogs.length === 0 && <Spacer />}
            <div style={styles.finishedSpacer}>FINISHED</div>
            {sortedRunLogs.map((log, idx) => (
              <>
                {isEmpty(log.entry) ? (
                  <UnassignedRunLog
                    event={event}
                    key={log.uuid}
                    log={log}
                    onOpen={() => handleAssignEntryClick(log)}
                    updateRunLog={updateRunLog}
                    timingEnabled={timingEnabled}
                    debugEnabled={debugEnabled}
                  />
                ) : (
                  <FinishedRunLog
                    event={event}
                    key={log.uuid}
                    idx={entryRunIdx(log)}
                    log={log}
                    updateRunLog={updateRunLog}
                    timingEnabled={timingEnabled}
                    debugEnabled={debugEnabled}
                  />
                )}
                <Spacer queued={false} />
              </>
            ))}
          </div>
        )}
      </div>
      <AssignEntryModal
        assignEntryToRunLog={assignEntryToUnassignedLog}
        entries={entries}
        isOpen={isAssignEntryModalOpen}
        onOpen={onAssignEntryModalOpen}
        onClose={onAssignEntryModalClose}
      />
      <QueueOtherModal
        refetchEntries={refetchEntries}
        eventId={event?.id}
        onSubmit={createQueueLogFromNextEntry}
        entries={entries}
        queuedRunLogs={queuedRunLogs}
        isOpen={isQueueOtherModalOpen}
        onOpen={onQueueOtherModalOpen}
        onClose={onQueueOtherModalClose}
      />
    </div>
  );
}

function Spacer({ queued }) {
  return (
    <div
      style={{ height: 30, backgroundColor: queued ? "#F6E05E" : "#EDF2F7" }}
    ></div>
  );
}

function QueueNextButton({ entry, run, onClick }) {
  return (
    <div style={styles.queueNextButton} onClick={() => onClick(entry, run)}>
      <strong style={{ color: "white" }}>+ NEXT ENTRY</strong>
      <div style={{ fontSize: 12, marginLeft: 13 }}>
        <p>
          {entry.group}
          {entry.entry_num} - {entry.class_short} - {entry.class_modifier}- R1
        </p>
        <p>{entry.name}</p>
        <p>
          {entry.vehicle} - {entry.vehicle_color}
        </p>
      </div>
    </div>
  );
}

function QueueOtherButton({ onClick }) {
  return (
    <div style={styles.queueOtherButton} onClick={() => onClick()}>
      <strong style={{ color: "#2B6CB0" }}>+ OTHER ENTRY</strong>
      <div style={{ fontSize: 12, marginLeft: 13 }}>
        <p>New or existing entry</p>
      </div>
    </div>
  );
}

function TimerDisabledWarning() {
  return (
    <div style={styles.timerDisabledWarning}>
      <strong style={{ color: "tomato", paddingLeft: 12 }}>
        TIMING DISABLED
      </strong>
      <div style={{ fontSize: 12, marginLeft: 13 }}>
        <p>Enable timing to begin logging times</p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#CBD5E0",
    overflow: "scroll",
    height: "100%",
    minHeight: "100vh",
    maxHeight: "100vh",
  },
  queueButtons: {
    display: "flex",
    flexDirection: "row",
    fontSize: 14,
    height: 104,
  },
  queueNextButton: {
    cursor: "pointer",
    flex: 1,
    color: "white",
    backgroundColor: "#2C5282",
    padding: 6,
  },
  timerDisabledWarning: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    backgroundColor: "white",
    color: "#555555",
    padding: 6,
  },
  queueOtherButton: {
    cursor: "pointer",
    flex: 1,
    backgroundColor: "white",
    color: "#555555",
    padding: 6,
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    boxShadow: "0px 0px 2px #000000",
  },
  header: {
    textAlign: "center",
    backgroundColor: "#EDF2F7",
    borderBottom: "1px solid #A0AEC0",
    color: "#171923",
    fontWeight: "bold",
    padding: 6,
    zIndex: 2,
  },
  body: {
    flex: 1,
    backgroundColor: "#EDF2F7",
  },
  queuedSpacer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 30,
    fontSize: 14,
    fontWeight: "bold",
    color: "#555555",
    textAlign: "center",
    backgroundColor: "#F6E05E",
  },
  finishedSpacer: {
    marginTop: -30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 30,
    fontSize: 14,
    fontWeight: "bold",
    color: "rgb(85, 85, 85)",
    textAlign: "center",
    backgroundColor: "#EDF2F7",
    borderTop: "1px solid #EDF2F7",
  },
};
