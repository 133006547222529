import _ from "lodash";
import { useRef, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Heading,
  Progress,
  CheckboxGroup,
  Checkbox,
  VStack,
} from "@chakra-ui/react";
import { useMsrGroups } from "../../queries";

export default function GroupsSelect({
  close,
  event,
  msrEvent,
  selectedMsrSegments,
  setSelectedGroups,
}) {
  const groupsRef = useRef();
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);

  const selectedSegmentIds = selectedMsrSegments.map((s) => s.id);
  const {
    isLoading,
    isError,
    data: groups,
  } = useMsrGroups(msrEvent.id, selectedSegmentIds);

  const handleGroupsChange = (groupIds) => setSelectedGroupIds(groupIds);

  const handleNext = () => {
    const selected = groups.filter((g) => selectedGroupIds.includes(g.id));
    setSelectedGroups(selected);
  };

  return (
    <ModalContent>
      <ModalHeader>Import MSR event</ModalHeader>
      {!isLoading && (
        <>
          <ModalBody>
            <FormControl>
              <FormLabel>Select run groups to import</FormLabel>
              <CheckboxGroup
                colorScheme="green"
                ref={groupsRef}
                onChange={handleGroupsChange}
                value={selectedGroupIds}
              >
                <VStack alignItems="flex-start">
                  {groups
                    .sort((a, b) => a.longName.localeCompare(b.longName))
                    .map((group) => {
                      const alreadyImported = _.get(event, "groups", []).some(
                        (g) => g === group.longName.replace(/group\s*/i, "")
                      );

                      return (
                        <Checkbox
                          key={`msr-group-${group.id}`}
                          value={group.id}
                          isDisabled={alreadyImported}
                        >
                          <strong>{group.longName}</strong> (
                          {group.entryCount || 0} entries
                          {alreadyImported && ", already imported"})
                        </Checkbox>
                      );
                    })}
                </VStack>
              </CheckboxGroup>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={close} ml={3}>
              Close
            </Button>
            <Button colorScheme="blue" onClick={handleNext} ml={3}>
              Next
            </Button>
          </ModalFooter>
        </>
      )}
      {isLoading && (
        <ModalBody>
          <Progress size="xs" isIndeterminate my={8} />
        </ModalBody>
      )}
      {isError && (
        <ModalBody>
          <Heading size="sm" pb={4} color="red.500">
            Unable to load MSR groups. Please try again in a minute.
          </Heading>
        </ModalBody>
      )}
    </ModalContent>
  );
}
