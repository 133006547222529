import _ from "lodash";
import { useRef } from "react";
import {
  Button,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Heading,
  Progress,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { validatePresent } from "../../validators";

export default function EventDetails({ close, updateEvent, msrEvent }) {
  const isLoading = false;
  const error = null;

  const handleSubmit = (values) => {
    updateEvent(values);
  };

  return (
    <ModalContent>
      <Formik
        initialValues={_.pick({}, "name", "min_events")}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <>
            <ModalHeader>Import MSR event</ModalHeader>
            {!isLoading && (
              <>
                <ModalBody>
                  <Form>
                    <Field name="name" validate={validatePresent("name")}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                          mb={6}
                        >
                          <FormLabel htmlFor="name">Name</FormLabel>
                          <Input
                            {...field}
                            id="name"
                            placeholder="name of the series"
                            autoComplete="off"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="start_date"
                      validate={validatePresent("start_date")}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.start_date && form.touched.start_date
                          }
                          mb={6}
                        >
                          <FormLabel htmlFor="start_date">Start Date</FormLabel>
                          <Input
                            {...field}
                            id="start_date"
                            placeholder="date of event"
                            autoComplete="off"
                            type="date"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.start_date}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={close} ml={3}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={props.handleSubmit}
                    ml={3}
                  >
                    Next
                  </Button>
                </ModalFooter>
              </>
            )}
            {isLoading && (
              <ModalBody>
                <Progress size="xs" isIndeterminate my={8} />
              </ModalBody>
            )}
            {error && (
              <ModalBody>
                <Heading size="sm" pb={4} color="red.500">
                  An error occurred fetching events from MSR. Try again in a
                  minute.
                </Heading>
              </ModalBody>
            )}
          </>
        )}
      </Formik>
    </ModalContent>
  );
}
