import { isEmpty } from "lodash";

export function firstBlankRun(runs) {
  return runs.find(
    (run) =>
      isEmpty(run.scratchTime ? run.scratchTime.toString() : "") &&
      run.penaltyCount === 0 &&
      !run.dnf
  );
}

export function debugUuid(uuid) {
  return isEmpty(uuid) ? "" : `${uuid.slice(0, 16)}...`;
}
