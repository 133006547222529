import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Screen,
  TopControls,
  TopLeft,
  TopRight,
  Main,
  MainLeft,
  MainRight,
} from "../../components/template";
import Head from "../../components/Head";
import Footer from "../../components/Footer";
import { api } from "../../api";
import NewEventButton from "../../components/NewEventButton";
import EventActionsMenu from "../../components/EventActionsMenu";
import LoadingScreen from "../../components/LoadingScreen";
import SeriesSidebar from "./SeriesSidebar";
import Pagy from "../../components/Pagy";
import { useLoginOnUnauthorized } from "../../hooks";

const colorScheme = {
  created: "yellow",
  completed: "green",
  archived: null,
};

function SeriesDetail() {
  const handleUnauthorizedError = useLoginOnUnauthorized();

  let params = useParams();
  let { id: seriesId, page, items } = params;
  seriesId ||= "";
  page ||= 1;
  items ||= 10;

  const { data: seriesData, refetch: refetchSeries } = useQuery(
    `series-${seriesId}`,
    api.eventsSeriesShow(seriesId),
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  console.log("seriesData", seriesData);

  const {
    isLoading,
    data,
    error,
    refetch: refetchEvents,
  } = useQuery(
    `events-${seriesId}-${page}-${items}`,
    api.events(seriesId, page, items),
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onError: handleUnauthorizedError,
    }
  );

  const events = data ? data.data : [];

  if (isLoading) return <LoadingScreen />;

  return (
    <Screen>
      <Head title="Events" />
      <TopControls>
        <TopLeft>
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink href="/events">Events</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href="#">
                <strong>{seriesData.name}</strong>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </TopLeft>
        <TopRight>
          <NewEventButton />
        </TopRight>
      </TopControls>
      <Main>
        <MainLeft>
          <SeriesSidebar
            series={seriesData}
            refetchEventSeries={refetchSeries}
          />
        </MainLeft>
        <MainRight>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Event</Th>
                <Th>Series</Th>
                <Th>Date</Th>
                <Th>Venue</Th>
                <Th>Status</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {events.map((event) => (
                <Tr key={`event-${event.id}`}>
                  <Td>
                    <Link to={`/events/${event.id}`}>
                      <Text as="span" color="blue.500">
                        {event.name}
                      </Text>
                    </Link>
                  </Td>
                  <Td>
                    {event.series && (
                      <Text as="span">{event.series?.name}</Text>
                    )}
                  </Td>
                  <Td>{event.start_date}</Td>
                  <Td>{event.venue}</Td>
                  <Td>
                    <Badge colorScheme={colorScheme[event.state]}>
                      {event.state}
                    </Badge>
                  </Td>
                  <Td>
                    <EventActionsMenu
                      event={event}
                      refetchEvents={refetchEvents}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagy pagy={data.pagy} />
        </MainRight>
      </Main>
      <Footer />
    </Screen>
  );
}

// SeriesDetail.whyDidYouRender = true;

export default SeriesDetail;
