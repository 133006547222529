import { isNull, get } from "lodash";
import React, { createRef, useEffect, useRef } from "react";
import { Box, Button, Center, Flex, IconButton, Text } from "@chakra-ui/react";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import { debugUuid } from "../../utils";

const TimingCell = (props) => {
  const {
    runsSig,
    queueIdx,
    // isQueued,
    timingEnabled,
    debugEnabled,
    event,
    entry,
    runIdx,
    tabIndex,
    setSelectedRun,
    cellRef,
    updateEntryRun,
    updateCacheForEntry,
    advanceToNextSelectedRun,
    isBestRun,
  } = props;
  const run = entry.runs && entry.runs[runIdx];
  const isQueued = queueIdx !== -1 && !isNull(queueIdx);
  const editable =
    timingEnabled && !isQueued && get(event, "state") !== "archived";

  useEffect(() => {
    if (cellRef && cellRef.current.value !== run.scratchTime) {
      cellRef.current.value = run.scratchTime;
    }
  }, [entry.id, runsSig]);

  const dnfBtnRef = useRef(createRef());
  const addPenaltyBtnRef = useRef(createRef());
  const subtractPenaltyBtnRef = useRef(createRef());

  const updateRun = (data) => {
    entry.runs[runIdx] = { ...entry.runs[runIdx], ...data };
    updateEntryRun(entry, { runs: entry.runs });
    updateCacheForEntry(entry);
  };

  const handleTimeFocus = (ev) => {
    setSelectedRun([entry.entry_id, runIdx]);
  };

  const handleTimeChange = (ev) => {
    if (run.scratchTime === ev.target.value) return;
    updateRun({ scratchTime: ev.target.value });
  };

  const handleDnfClick = (ev) => {
    if (!editable) return;
    updateRun({ dnf: !run.dnf });
    dnfBtnRef.current.blur();
  };

  const handlePenaltyAdd = (ev) => {
    if (!editable) return;
    updateRun({ penaltyCount: parseInt(run.penaltyCount || 0) + 1 });
    addPenaltyBtnRef.current.blur();
  };

  const handlePenaltySubtract = (ev) => {
    if (!editable) return;
    const existingCount = parseInt(run.penaltyCount || 0);
    updateRun({ penaltyCount: existingCount > 0 ? existingCount - 1 : 0 });
    subtractPenaltyBtnRef.current.blur();
  };

  if (!run) return <EmptyCell />;

  const styles = generateStyles(props);

  return (
    <Flex direction="row">
      <Flex direction="column">
        <IconButton
          visibility={editable || run.dnf ? "visible" : "hidden"}
          ref={addPenaltyBtnRef}
          width="100%"
          height="100%"
          icon={editable && <AddIcon />}
          onClick={handlePenaltyAdd}
          borderRadius="0px"
          borderBottom="1px solid #E2E8F0"
          bg="gray.50"
          color="gray.300"
          fontSize={10}
          minWidth={30}
        >
          +
        </IconButton>
        <IconButton
          visibility={editable || run.dnf ? "visible" : "hidden"}
          ref={subtractPenaltyBtnRef}
          height="100%"
          width="100%"
          icon={editable && <MinusIcon />}
          borderRadius="0px"
          bg="gray.50"
          color="gray.300"
          fontSize={10}
          minWidth={30}
          onClick={handlePenaltySubtract}
        >
          -
        </IconButton>
      </Flex>
      <Flex direction="column" flexGrow="1">
        <Center color="gray.800" height="50px" borderBottom="1px solid #E2E8F0">
          <input
            ref={cellRef}
            id={`${entry.entry_id}-${runIdx}`}
            tabIndex={tabIndex}
            disabled={!editable}
            defaultValue={run && run.scratchTime}
            min="0"
            max="999.999"
            onBlur={handleTimeChange}
            onFocus={handleTimeFocus}
            style={{
              ...styles.timeInputStyles,
              display: isQueued ? "none" : "block",
            }}
            autoComplete="off"
          />
          <Text
            style={{
              textAlign: "center",
              display: isQueued ? "block" : "none",
              padding: 10,
              width: "100%",
              backgroundColor: "#FAF089",
            }}
          >
            NEXT {queueIdx + 1}
          </Text>
          {debugEnabled && (
            <span style={{ fontSize: 12 }}>{debugUuid(run.id)}</span>
          )}
          <Center width="40px">
            <Text style={styles.penaltyStyles} color="tomato" fontWeight="bold">
              {!run || run.penaltyCount.toString() === "0"
                ? ""
                : `+${run.penaltyCount}`}
            </Text>
          </Center>
        </Center>
        <Flex
          direction="row"
          justifyContent="space-between"
          height="50px"
          mr="40px"
        >
          <Center
            color={run.dnf ? "red.400" : "gray.200"}
            fontSize={20}
            textAlign="center"
            flexGrow="1"
            width="100%"
            onClick={handleDnfClick}
            tabIndex="-1"
          >
            <Button
              visibility={editable || run.dnf ? "visible" : "hidden"}
              variant="unstyled"
              width="100%"
              ref={dnfBtnRef}
              borderRadius="0px"
            >
              DNF
            </Button>
          </Center>
        </Flex>
      </Flex>
    </Flex>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.timingEnabled === nextProps.timingEnabled &&
    prevProps.debugEnabled === nextProps.debugEnabled &&
    prevProps.queueIdx === nextProps.queueIdx &&
    prevProps.tabIndex === nextProps.tabIndex &&
    prevProps.entry.id === nextProps.entry.id &&
    prevProps.isSelectedRun === nextProps.isSelectedRun &&
    prevProps.runsSig === nextProps.runsSig &&
    prevProps.run === nextProps.run &&
    prevProps.entry.runs === nextProps.entry.runs
  );
}

function generateStyles({
  runIdx,
  isSelectedRun,
  entry,
  timingEnabled,
  isBestRun,
}) {
  const run = entry.runs && entry.runs[runIdx];

  return {
    timeInputStyles: {
      // zIndex: 50,
      backgroundColor: isSelectedRun ? "#FAF089" : "white",
      textDecoration: run.dnf ? "line-through" : "none",
      height: "97%",
      width: "97%",
      textAlign: "center",
      fontFamily: "monospace",
      fontSize: "18px",
      fontWeight: !timingEnabled && isBestRun ? "bold" : "normal",
    },
    penaltyStyles: {
      backgroundColor: "white",
      height: "97%",
      width: "100%",
      textAlign: "center",
      fontFamily: "monospace",
      fontSize: "14px",
    },
    penaltyInputStyles: {
      height: "40px",
      textAlign: "center",
      width: "100%",
      color: "red",
      fontFamily: "monospace",
      // fontSize: "20px",
    },
  };
}

const EmptyCell = () => <Box direction="row" bg="gray.200" height={100}></Box>;

// TimingCell.whyDidYouRender = true;

export default React.memo(TimingCell, areEqual);
