import { useState } from "react";
import { Button } from "@chakra-ui/react";
import ImportMsrEvent from "../components/ImportMsrEvent";
import NewEvent from "../components/NewEvent/NewEvent";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";

export default function NewEventButton() {
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);

  const handleImportClick = () => setIsImportModalOpen(true);
  const handleImportModalClose = () => setIsImportModalOpen(false);

  const handleNewClick = () => setIsNewModalOpen(true);
  const handleNewModalClose = () => setIsNewModalOpen(false);

  return (
    <>
      <Menu>
        <MenuButton as={Button} colorScheme="green" size="sm">
          + New Event
        </MenuButton>
        <MenuList>
          <MenuItem onClick={handleImportClick}>Import MSR event</MenuItem>
          <MenuItem onClick={handleNewClick}>Create new event</MenuItem>
        </MenuList>
      </Menu>
      {isImportModalOpen && (
        <ImportMsrEvent
          isOpen={isImportModalOpen}
          close={handleImportModalClose}
        />
      )}
      {isNewModalOpen && (
        <NewEvent isOpen={isNewModalOpen} close={handleNewModalClose} />
      )}
    </>
  );
}
