import { useRef } from "react";
import { useQuery } from "react-query";
import {
  Button,
  Select,
  FormControl,
  FormLabel,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Heading,
  Progress,
} from "@chakra-ui/react";
import { api } from "../../api";

export default function IndexFactorGroupSelect({ close, onFinish }) {
  const selectRef = useRef();

  const { isLoading, data, error } = useQuery(
    "index-factor-groups",
    api.indexFactorGroups,
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleNext = () => {
    onFinish(selectRef.current.value);
  };

  const groups = data ? data : [];

  return (
    <ModalContent>
      <ModalHeader>Import MSR event</ModalHeader>

      {!isLoading && (
        <>
          <ModalBody>
            <FormControl>
              <FormLabel>Select a PAX configuration:</FormLabel>
              <Select ref={selectRef} placeholder="Select a PAX configuration">
                {groups.map((s) => (
                  <option key={`groups-${s.id}`} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={close} ml={3}>
              Close
            </Button>
            <Button colorScheme="blue" onClick={handleNext} ml={3}>
              Next
            </Button>
          </ModalFooter>
        </>
      )}
      {isLoading && (
        <ModalBody>
          {/* <Heading size="sm" pb={4}>
            Importing segments from MSR...
          </Heading> */}
          <Progress size="xs" isIndeterminate my={8} />
        </ModalBody>
      )}
      {error && (
        <ModalBody>
          <Heading size="sm" pb={4} color="red.500">
            Unable to fetch PAX configurations. Please try again in a minute.
          </Heading>
        </ModalBody>
      )}
    </ModalContent>
  );
}
