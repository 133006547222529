import { useState } from "react";
import { Button } from "@chakra-ui/react";
import SeriesForm from "./SeriesForm";

export default function SeriesFormButton({ label, onMutate, initialSeries }) {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClick = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  return (
    <>
      <Button colorScheme="green" onClick={handleClick} size="sm">
        {label}
      </Button>

      {isModalOpen && (
        <SeriesForm
          initialSeries={initialSeries}
          isOpen={isModalOpen}
          onClose={handleClose}
          onMutate={onMutate}
        />
      )}
    </>
  );
}
