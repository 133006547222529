import _ from "lodash";
import { useQuery, useQueries } from "react-query";
import { api } from "./api";

export function useMsrGroups(eventId, segmentIds) {
  const segmentQueries = useQueries(
    segmentIds.map((segmentId) => {
      return {
        queryKey: ["msr-segment-assignments", segmentId],
        queryFn: api.msrSegmentAssignments(eventId, segmentId),
      };
    })
  );
  const isLoading = segmentQueries.some((query) => query.isLoading);
  const isError = segmentQueries.some((query) => query.isError);
  const results = segmentQueries.flatMap((query) => query.data);

  let groups = [];
  if (!isLoading && !isError) {
    const entriesByGroup = _.groupBy(results, "groupId");
    groups = _(results)
      .map((r) => ({
        longName: r.group,
        id: r.groupId,
        entryCount: entriesByGroup[r.groupId].length,
      }))
      .uniqBy("id")
      .orderBy(["longName"], ["asc"])
      .value();
  }

  return {
    isLoading,
    isError,
    data: groups,
  };
}

export function useIndexFactorGroups() {
  return useQuery("index-factor-groups", api.indexFactorGroups, {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}
