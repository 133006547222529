import { Box, Text } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";

function Pagy({ pagy }) {
  let params = useParams();
  let { id, page, items } = params;
  id ||= "";
  page ||= 1;
  items ||= 10;

  const linkTo = (series) =>
    id === ""
      ? `/events/page/${series}/items/${items}`
      : `/series-events/${id}/page/${series}/items/${items}`;

  if (pagy.pages <= 1) return;

  return (
    <Box width="100%" textAlign="right" p={8}>
      {pagy.series.map((series) =>
        typeof series === "string" ? (
          <Text
            as="span"
            p={2}
            style={{ textDecoration: series === "gap" ? "" : "underline" }}
          >
            {series === "gap" ? "..." : series}
          </Text>
        ) : (
          <Link to={linkTo(series)}>
            <Text as="span" p={2} color="blue.500">
              {series}
            </Text>
          </Link>
        )
      )}
    </Box>
  );
}

Pagy.whyDidYouRender = true;

export default Pagy;
