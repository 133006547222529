import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Box,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Screen,
  TopControls,
  TopLeft,
  TopRight,
  Main,
  MainLeft,
  MainRight,
} from "../../components/template";
import Head from "../../components/Head";
import Footer from "../../components/Footer";
import { api } from "../../api";

function IndexFactorGroup() {
  const { id: groupId } = useParams();

  const { isLoading, data, error } = useQuery(
    `index-factor-group-${groupId}`,
    api.indexFactorGroup(groupId),
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const group = data ? data : [];

  return (
    <Screen>
      <Head title={group ? group.name : "Index Factor Group"} />
      <TopControls>
        <TopLeft>
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink href="/settings">Settings</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/index-factor-groups">
                Index Factors (PAX)
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href="#">
                <strong>{group.name}</strong>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </TopLeft>
        <TopRight>
          <Button colorScheme="green" size="sm">
            Placeholder
          </Button>
        </TopRight>
      </TopControls>
      <Main>
        <MainLeft>
          <Heading as="h5" size="sm" pt="5" color="gray.500">
            DETAILS
          </Heading>
          <Box spacing={0} pt="4">
            <Stat>
              <StatLabel>Status</StatLabel>
              <StatNumber style={{ fontSize: 16 }}>Active</StatNumber>
            </Stat>
            <Stat mt={6}>
              <StatLabel>Linked To</StatLabel>
              <StatNumber style={{ fontSize: 16 }}>
                {group.global_configuration?.key || "-"}
              </StatNumber>
            </Stat>
          </Box>
        </MainLeft>
        <MainRight heading="Index Factors (PAX)">
          <TableContainer>
            <Table size="sm" variant="simple">
              <Thead>
                <Tr>
                  <Th>Class</Th>
                  <Th textAlign="center">Active?</Th>
                  <Th textAlign="right">Factor</Th>
                </Tr>
              </Thead>
              <Tbody>
                {group?.index_factors?.map((g) => (
                  <Tr>
                    <Td>{g.comp_class.short_name}</Td>
                    <Td textAlign="center">true</Td>
                    <Td textAlign="right">{parseFloat(g.factor).toFixed(3)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </MainRight>
      </Main>
      <Footer />
    </Screen>
  );
}

// IndexFactorGroup.whyDidYouRender = true;

export default IndexFactorGroup;
