import { useState } from "react";
import { Button } from "@chakra-ui/react";
import EventDetailForm from "./EventDetailForm";

export default function EventDetailFormButton({
  label,
  onMutate,
  initialEvent,
}) {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClick = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  return (
    <>
      <Button colorScheme="green" onClick={handleClick} size="sm">
        {label}
      </Button>

      {isModalOpen && (
        <EventDetailForm
          initialEvent={initialEvent}
          isOpen={isModalOpen}
          onClose={handleClose}
          onMutate={onMutate}
        />
      )}
    </>
  );
}
