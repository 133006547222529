import { isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useToast as useChakraToast } from "@chakra-ui/react";
import { api } from "./api";
import useWebSocket, { ReadyState } from "react-use-websocket";

export function useToast() {
  const toast = useChakraToast();

  const errorToast = (desc) => {
    toast({
      title: "Error",
      description: desc,
      status: "error",
      duration: 6000,
      isClosable: true,
    });
  };

  return {
    errorToast,
  };
}

export function useWindowEvent(event, handler, deps = [], passive = false) {
  useEffect(() => {
    document.addEventListener(event, handler, passive);

    return function cleanup() {
      document.removeEventListener(event, handler);
    };
  }, deps);
}

export function useCompClasses() {
  const handleUnauthorizedError = useLoginOnUnauthorized();

  return useQuery("comp-classes", api.compClasses, {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: handleUnauthorizedError,
  });
}

export function useClassModifiers() {
  const handleUnauthorizedError = useLoginOnUnauthorized();

  return useQuery("class-modifiers", api.classModifiers, {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: handleUnauthorizedError,
  });
}

export function useMsrEvent(msrEventId) {
  return useQuery(["msr-event", msrEventId], api.msrEvent(msrEventId), {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}

export function useLoginOnUnauthorized() {
  const history = useHistory();

  return (error) => {
    if (error && error?.response?.status === 401) {
      history.push("/login");
    }
  };
}

export function useTimingMonitor() {
  const { lastMessage, readyState } = useWebSocket("ws://localhost:443/", {
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 50,
    reconnectInterval: 5000,
  });
  const [loggedTime, setLoggedTime] = useState(null);
  const [logId, setLogId] = useState(null);
  const pattern = new RegExp("^[0-9]{1,3}.[0-9]{3}$");

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      const { message: time } = data;

      if (pattern.test(time)) {
        setLoggedTime(parseFloat(time));
        setLogId(uuidv4());
      }
    }
  }, [lastMessage]);

  return { loggedTime, logId, readyState, connectionStatus };
}

export function useRunLogs({ event, selectedRunGroup, entries }) {
  return useQuery(
    `event-${event?.id}-run-logs-${selectedRunGroup}`,
    api.runLogs(event?.id, selectedRunGroup),
    {
      select: (data) => {
        const transformed = data.map((log) => ({
          entry: entries.find((e) => e.id === log.entry_id),
          ...log,
        }));
        return transformed;
      },
      enabled:
        !isEmpty(event) && !isEmpty(selectedRunGroup) && entries.length > 0, // Prevent fetching until we have entries so we can stitch/transform log data
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
}
