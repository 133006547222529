import { has, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Modal, ModalOverlay, useToast } from "@chakra-ui/react";
import EventDetails from "./EventDetails";
import Loading from "./Loading";
import Review from "./Review";
import SeriesSelect from "./SeriesSelect";
import { api } from "../../api";
import IndexFactorGroupSelect from "./IndexFactorGroupSelect";

export default function NewEvent({ isOpen, close }) {
  const history = useHistory();
  const toast = useToast();
  const [isDisabled, setIsDisabled] = useState(false);
  const [event, setEvent] = useState({});
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectSeriesShown, setSelectSeriesShown] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [selectedPaxConfig, setSelectedPaxConfig] = useState(null);

  console.log("EVENT", event);

  const updateEvent = (values) => {
    setEvent((prevState) => ({
      ...prevState,
      ...values,
    }));
  };

  const createEventMutation = useMutation(
    (variables) => api.createEvent(variables),
    {
      onSuccess: (params) => {
        toast({
          title: "Success",
          description: "Event imported successfully",
          status: "success",
          duration: 6000,
          isClosable: true,
        });
        close();
        history.push(`/events/${params.id}`);
      },
    }
  );

  const handleSeriesSelectFinish = (seriesId) => {
    setSelectedSeries(seriesId);
    setSelectSeriesShown(true);
  };

  const handlePaxConfigSelectFinish = (groupId) => {
    setSelectedPaxConfig(groupId);
  };

  useEffect(() => {
    setIsDisabled(createEventMutation.isLoading);
  }, [createEventMutation.isLoading]);

  const importEvent = () => {
    console.log("IMPORTING EVENT", event);
    createEventMutation.mutate({
      // ...event
      name: event.name,
      start_date: event.start_date,
      event_series_id: event.event_series_id,
      index_factor_group_id: event.index_factor_group_id,
    });
  };

  const showEventDetailsSelect = isEmpty(event);
  const showSeriesSelect =
    !showEventDetailsSelect && !has(event, "event_series_id");
  const showIndexSelect =
    !showEventDetailsSelect &&
    !showSeriesSelect &&
    !has(event, "index_factor_group_id");
  const showReview =
    !showEventDetailsSelect &&
    !showSeriesSelect &&
    !showIndexSelect &&
    !showIndexSelect &&
    !createEventMutation.isLoading;

  return (
    <Modal
      onClose={close}
      size="xl"
      isOpen={isOpen}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      {showEventDetailsSelect && (
        <EventDetails
          updateEvent={updateEvent}
          close={close}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
        />
      )}
      {showSeriesSelect && (
        <SeriesSelect
          updateEvent={updateEvent}
          close={close}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          onFinish={handleSeriesSelectFinish}
        />
      )}
      {showIndexSelect && (
        <IndexFactorGroupSelect
          updateEvent={updateEvent}
          close={close}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          onFinish={handlePaxConfigSelectFinish}
        />
      )}
      {showReview && (
        <Review
          close={close}
          event={event}
          selectedSeries={selectedSeries}
          selectedGroups={selectedGroups}
          selectedPaxConfig={selectedPaxConfig}
          importEvent={importEvent}
          error={createEventMutation.error}
        />
      )}
      {createEventMutation.isLoading && <Loading />}
    </Modal>
  );
}
