import _ from "lodash";
import {
  Button,
  FormErrorMessage,
  Heading,
  Input,
  Modal,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Progress,
  FormControl,
  FormLabel,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useMutation } from "react-query";
import { api } from "../../api";
import { validatePresent } from "../../validators";

export default function AddClass({ initialClass, isOpen, onClose, onMutate }) {
  const handleSuccess = (data) => {
    toast({
      title: "Success",
      description: `Class ${data.long_name} - ${data.short_name} saved`,
      status: "success",
      duration: 6000,
      isClosable: true,
    });
    onClose();
    onMutate(data);
  };

  const toast = useToast();

  const addClassMutation = useMutation((variables) => api.addClass(variables), {
    onSuccess: handleSuccess,
    // onMutate: onMutate, // TODO: OFFLINE
  });

  const updateClassMutation = useMutation(
    (variables) => api.updateClass(initialClass.id, variables),
    {
      onSuccess: handleSuccess,
      onError: (err) => {
        let errorDescription;
        if (err.response.headers["content-type"].includes("application/json")) {
          errorDescription = _(err.response.data || {})
            .toPairs()
            .flatMap()
            .join(" ");
        }

        toast({
          title: "Error",
          description: errorDescription || "Error saving class",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      },
    }
  );

  const isFormLoading =
    addClassMutation.isLoading || updateClassMutation.isLoading;

  const handleSubmit = (values) => {
    if (initialClass.id) {
      updateClassMutation.mutate(values);
    } else {
      addClassMutation.mutate(values);
    }
  };

  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <Formik
          initialValues={_.pick(initialClass, "short_name", "long_name")}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <>
              <ModalHeader>Add Class</ModalHeader>
              {isFormLoading && (
                <ModalBody>
                  <Heading size="sm">
                    <Text>Saving</Text>
                    <Progress size="xs" isIndeterminate my={8} />
                  </Heading>
                </ModalBody>
              )}

              {!isFormLoading && (
                <ModalBody>
                  <Form>
                    <Field
                      name="long_name"
                      validate={validatePresent("long_name")}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.long_name && form.touched.long_name
                          }
                          mb={6}
                        >
                          <FormLabel htmlFor="long_name">Long Name</FormLabel>
                          <Input
                            {...field}
                            id="long_name"
                            placeholder="full class name (eg: Super Street)"
                            autoComplete="off"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.long_name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="short_name"
                      validate={validatePresent("short_name")}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.short_name && form.touched.short_name
                          }
                          mb={6}
                        >
                          <FormLabel htmlFor="short_name">Short Name</FormLabel>
                          <Input
                            {...field}
                            id="short_name"
                            placeholder="abbreviated class name (eg: SS)"
                            autoComplete="off"
                            isRequired
                          />
                          <FormErrorMessage>
                            {form.errors.short_name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Form>
                </ModalBody>
              )}

              <ModalFooter>
                <Button onClick={onClose} ml={3}>
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  ml={3}
                  type="submit"
                  onClick={props.handleSubmit}
                >
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}
