import { createRef, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  Badge,
  Button,
  FormControl,
  FormLabel,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  CheckboxGroup,
  Checkbox,
  Heading,
  Progress,
  VStack,
} from "@chakra-ui/react";
import { api } from "../../api";

export default function SegmentsSelect({
  msrEvent,
  close,
  setSelectedMsrSegments,
}) {
  const segmentsRef = useRef();
  const [selectedSegments, setSelectedSegments] = useState([]);

  const { isLoading, data, error } = useQuery(
    "msr-event-segments",
    api.msrEventSegments(msrEvent.id),
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleSegmentsChange = (selectedSegmentIds) => {
    setSelectedSegments(selectedSegmentIds);
  };

  const handleNext = () => {
    const selected = msrSegments.filter((s) => selectedSegments.includes(s.id));
    setSelectedMsrSegments(selected);
  };

  const ErrorBody = ({ children }) => (
    <ModalBody>
      <Heading size="sm" pb={4} color="red.500">
        {children}
      </Heading>
    </ModalBody>
  );

  const msrSegments = data ? data : [];

  return (
    <ModalContent>
      <ModalHeader>Import MSR event</ModalHeader>

      {!isLoading && (
        <>
          <ModalBody>
            <FormControl>
              <FormLabel>
                Select segments to import.{" "}
                <strong>
                  You probably only want to select{" "}
                  <span style={{ backgroundColor: "#FEFCBF" }}>
                    Competition Groups
                  </span>
                </strong>
                !
              </FormLabel>
              <CheckboxGroup
                colorScheme="green"
                ref={segmentsRef}
                onChange={handleSegmentsChange}
                value={selectedSegments}
              >
                <VStack alignItems="flex-start">
                  {msrSegments
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((segment) => (
                      <Checkbox
                        key={`msr-segment-${segment.id}`}
                        value={segment.id}
                      >
                        <strong>{segment.name}</strong> ({segment.groups.length}{" "}
                        groups, {segment.entryCount || 0} entries)
                      </Checkbox>
                    ))}
                </VStack>
              </CheckboxGroup>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={close} ml={3}>
              Close
            </Button>
            <Button colorScheme="blue" onClick={handleNext} ml={3}>
              Next
            </Button>
          </ModalFooter>
        </>
      )}
      {isLoading && (
        <ModalBody>
          {/* <Heading size="sm" pb={4}>
            Importing segments from MSR...
          </Heading> */}
          <Progress size="xs" isIndeterminate my={8} />
        </ModalBody>
      )}
      {error && (
        <ModalBody>
          <Heading size="sm" pb={4} color="red.500">
            Unable to import MSR event segments. Please try again in a minute.
          </Heading>
        </ModalBody>
      )}
    </ModalContent>
  );
}
